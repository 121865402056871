import { Product } from '@oriola-corporation/product-types';
import { CART_ITEM_CLICKED } from '../../../../../../components/cart/analytics';
import { PRESCRIPTION_CARD_CLICKED } from '../../../../../../components/expedition/analytics';
import { FLYOUT_PRODUCT_LIST_ITEM_CLICKED } from '../../../../../../components/product/flyoutProductList/analytics';
import { PRODUCT_CARD_CLICKED } from '../../../../../../components/product/productCard/analytics';
import { ProductCardProduct } from '../../../../../../components/product/productCard/types';
import { PRODUCT_LIST_ITEM_CLICKED } from '../../../../../../components/product/productListItem/analytics';
import { PRODUCT_BUNDLE_CLICKED } from '../../../../../../components/productBundle/analytics';
import { Metadata } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import {
  formatFakeRxProduct,
  formatProduct,
  GAFormattedProduct,
  toTrackableProduct,
} from '../utils/formatProduct';
import { parseListNameFromMetadata } from '../utils/parseListNameFromMetadata';

const trackProductClick = ({
  list = 'N/A',
  product,
  position,
}: {
  product: GAFormattedProduct;
  position: number;
  list?: string;
}) => {
  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list,
        },
        products: [{ ...product, position }],
      },
    },
  };
};

const trackRegularProductClicks = <T extends ProductCardProduct | Product>({
  event,
  metadata,
}: {
  event: {
    payload: {
      product: T;
      position: number;
    };
  };
  metadata: Metadata;
}) => {
  const product = formatProduct(toTrackableProduct(event.payload.product));
  const position = event.payload.position;
  return trackProductClick({ product, position, list: metadata.pageType as string });
};

export const productClickedTracker = createSimpleTracker({
  [FLYOUT_PRODUCT_LIST_ITEM_CLICKED]: trackRegularProductClicks,
  [PRODUCT_LIST_ITEM_CLICKED]: trackRegularProductClicks,
  [PRODUCT_CARD_CLICKED]: ({ event, metadata }) => {
    const listValue = (metadata.pageType as string) || event.payload.list || 'N/A';
    return trackProductClick({
      product: formatProduct(toTrackableProduct(event.payload.product)),
      position: event.payload.position,
      list: listValue,
    });
  },
  [CART_ITEM_CLICKED]: ({ event, metadata }) => {
    return trackProductClick({
      product: formatProduct(toTrackableProduct(event.payload.item)),
      position: 1,
      list: metadata.pageType as string,
    });
  },
  [PRESCRIPTION_CARD_CLICKED]: ({ event, metadata }) => {
    const list = parseListNameFromMetadata(metadata);
    return trackProductClick({
      product: formatFakeRxProduct(),
      position: event.payload.position,
      list,
    });
  },
  [PRODUCT_BUNDLE_CLICKED]: ({ event }) => {
    return trackProductClick({
      product: formatProduct(toTrackableProduct(event.payload.product)),
      position: event.payload.position,
      list: 'often-bought-together',
    });
  },
});
