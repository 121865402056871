export const FLYOUT_LINK_CLICKED = 'FLYOUT/LINK_CLICKED';

export interface FlyoutLinkClicked {
  type: typeof FLYOUT_LINK_CLICKED;
  payload: {
    id: string;
  };
}

export const SUBMENU_TOGGLED = 'FLYOUT/SUBMENU_TOGGLED';

export interface SubMenuToggled {
  type: typeof SUBMENU_TOGGLED;
  payload: {
    id: string;
    isOpen: boolean;
  };
}

export type AnalyticsEvents = FlyoutLinkClicked | SubMenuToggled;
