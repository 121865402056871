import { LINK_CLICKED } from '../../../../../../components/layout/footer/analytics';
import { Tracker } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const footerTracker: Tracker = createSimpleTracker({
  [LINK_CLICKED]: ({ event }) => ({
    event: 'footer',
    event_info: {
      event_name: 'footer_click',
      user_interaction: 'link_click',
      navigation_selection: convertTextToSlug(event.payload.id),
    },
  }),
});
