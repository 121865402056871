enum BannerVariants {
  noBanner = 'noBanner',
  topBanner = 'topBanner',
  bottomBanner = 'bottomBanner',
}

export type AppFeatures = {
  'aa-test_prod': boolean;
  'club-days-may': boolean;
  'aa-test-2-all': boolean;
  'rx-header-link': string;
  'collect-review': string;
  'checkout-upsale': string;
  'cart-modal-mobile': boolean;
  'qr-return-section': boolean;
  'cart-shipping-banner': BannerVariants;
};
