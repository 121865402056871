import React from 'react';
import { useTranslation } from 'react-i18next';

export const DiklofenakModalContent = () => {
  const { t } = useTranslation('productPage');
  return (
    <>
      <p className="mb-4 font-medium">{t('diclaimer.diklofenak.preamble')}</p>
      <h4 className="font-medium">{t('diclaimer.diklofenak.heading1')}</h4>
      <p className="mb-4">{t('diclaimer.diklofenak.paragraph1')}</p>
      <h4 className="font-medium">{t('diclaimer.diklofenak.heading2')}</h4>
      <p>{t('diclaimer.diklofenak.paragraph2')}</p>
    </>
  );
};
