export class ValidationError extends Error {
  private fields: string[] = [];

  constructor(fields: string[]) {
    super('Validation error');
    this.fields = fields;
  }

  public getFields() {
    return this.fields;
  }
}
