import i18n, { InitOptions } from 'i18next';
import React from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import namespaces from './locales/namespaces.json';

interface TranslationRecord {
  [key: string]: TranslationRecord | string;
}

interface TranslationResources {
  [key: string]: TranslationRecord;
}

const LOCALES_PATH = './locales';
const DEFAULT_LOCALE = 'sv-SE';

const loadResources = (locale: string) => {
  let resources: TranslationResources = {};
  namespaces.forEach((namespace) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const data = require(`${LOCALES_PATH}/${locale}/${namespace}.json`);

    resources = {
      [locale]: {
        ...resources[locale],
        [namespace]: data,
      },
    };
  });

  return resources;
};

const isDev = process.env.NODE_ENV === 'development';

interface Props {
  language?: string;
}

export const Provider: React.FC<React.PropsWithChildren<Props>> = ({
  language = DEFAULT_LOCALE,
  children,
}) => {
  const i18nextOptions: InitOptions = {
    defaultNS: 'common',
    ns: namespaces,
    fallbackLng: language,
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    appendNamespaceToCIMode: true,
    parseMissingKeyHandler: (key) => {
      if (isDev) {
        throw new Error(`Unknown translation key ${key}`);
      }
    },
    missingInterpolationHandler: (text, value) => {
      if (isDev) {
        throw new Error(`${value} was not included but expected when interpolating "${text}"`);
      }
    },
    react: {
      useSuspense: true,
    },
  };

  const i18nConfig = {
    lng: language,
    resources: loadResources(DEFAULT_LOCALE),
    ...i18nextOptions,
  };

  i18n.use(initReactI18next).init(i18nConfig);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

Provider.displayName = 'TranslationsProvider';
