import { PostMessage } from '../../../../../../templates/external/types';
import { isApp } from '../../../../../../utils/mobileAppUtils';

export const trackEvent = <T>(event: T) => {
  if (isApp()) {
    return window.postMessage({
      type: PostMessage.ANALYTICS_EVENT,
      ...event,
    });
  } else {
    if (window.dataLayer) {
      window.dataLayer.push(event);
    }
  }
};
