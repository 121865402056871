import axios, { AxiosError } from 'axios';
import { DuplicateMemberError, MemberNotMatureError, ValidationError } from './errors';
import { BonusCheck, MemberDetails, MemberStatus, Member, Purchase } from './types';

const isAxiosError = (error: Error): error is AxiosError => !!(error as AxiosError).response;

export const convertMemberErrors = async <T>(func: () => Promise<T>) => {
  try {
    return await func();
  } catch (error) {
    if (error instanceof Error && isAxiosError(error) && error.response) {
      convertToMemberError(error);
    }
    throw error;
  }
};

export const convertToMemberError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    switch (error?.response?.status) {
      case 422:
        if (error?.response?.data?.reason === 'USER_NOT_MATURE') {
          throw new MemberNotMatureError();
        }
        break;
      case 409:
        throw new DuplicateMemberError();
      case 400:
        throw new ValidationError(
          error?.response?.data?.errors.map((error: { field: string }) => error.field)
        );
    }
  }

  return error;
};

export const createApiClient = (baseUrl: string) => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getAxiosInstance: () => client,
    getMemberStatus: async () =>
      convertMemberErrors(async () => {
        const response = await client.get<MemberStatus>('/member/status');

        return response.data;
      }),

    getMember: async () =>
      convertMemberErrors(async () => {
        const response = await client.get<Member>('/member/info');
        return response.data;
      }),

    createMember: async (memberDetails: MemberDetails, referrer: 'MEMBER_LOGIN') =>
      convertMemberErrors(async () => {
        await client.post('/member', memberDetails, {
          params: {
            referrer,
          },
        });
      }),
    updateMember: async (memberDetails: MemberDetails) =>
      convertMemberErrors(async () => {
        await client.patch('/member/info/contact', memberDetails);
      }),

    deleteMember: async () =>
      convertMemberErrors(async () => {
        await client.delete('/member');
      }),

    getPurchases: async () =>
      convertMemberErrors(async () => {
        const response = await client.get<Purchase[]>('/member/purchases');

        return response.data;
      }),

    getBonusChecks: async () =>
      convertMemberErrors(async () => {
        const response = await client.get<BonusCheck[]>('/member/bonus-checks');

        return response.data;
      }),
  };
};

export type MemberApiClient = ReturnType<typeof createApiClient>;
