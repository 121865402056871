import { AuthMethod } from '@apis/authy';
import { MemberLogin, OLD_MEMBER_OPEN_LOGIN_MODAL, OLD_MEMBER_LOGIN } from '@packages/member';
import {
  LOGIN as RX_LOGIN,
  LOGOUT as RX_LOGOUT,
  LOGIN_HEADER as RX_LOGIN_HEADER,
  RX_HEADER_GO_TO_PRESCRIPTIONS,
} from '../../../../../../templates/prescription/analytics';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const mapLocationToContext = (location: MemberLogin['payload']['location']) => {
  switch (location) {
    case 'loginPage':
      return 'kundklubb_login';
  }
};

const createBaseLoginEvent = <
  T extends {
    label: string;
    method: AuthMethod;
    elementType: 'link' | 'button';
    provider: 'BankId';
  },
  E,
>(
  eventName: string,
  event: T,
  extraInfo: E
) => ({
  event: 'login',
  event_info: {
    event_name: eventName,
    link_text: convertTextToSlug(event.label),
    login_device: convertTextToSlug(event.method),
    login_action: event.elementType === 'button' ? 'button_click' : 'link_click',
    login_provider: event.provider,
    ...extraInfo,
  },
});

export const authenticationTracker = createSimpleTracker({
  [OLD_MEMBER_LOGIN]: ({ event }) =>
    createBaseLoginEvent('kundklubb_login', event.payload, {
      login_context: mapLocationToContext(event.payload.location),
    }),
  [OLD_MEMBER_OPEN_LOGIN_MODAL]: ({ event }) => ({
    event: 'login',
    event_info: {
      event_name: 'kundklubb_login',
      link_text: convertTextToSlug(event.payload.linkText),
      link_type: event.payload.linkType,
    },
  }),
  [RX_LOGIN]: ({ event }) =>
    createBaseLoginEvent(
      'rx_login',
      {
        ...event.payload,
        provider: 'BankId',
      },
      {}
    ),
  [RX_HEADER_GO_TO_PRESCRIPTIONS]: ({ event }) => ({
    event: 'login',
    event_info: {
      event_name: 'rx_login',
      link_text: event.payload.label,
      login_action: 'button_click_header',
    },
  }),
  [RX_LOGIN_HEADER]: ({ event }) =>
    createBaseLoginEvent(
      'rx_login',
      {
        ...event.payload,
        provider: 'BankId',
      },
      {
        login_action:
          event.payload.elementType === 'button' ? 'button_click_header' : 'link_click_header',
        login_provider: event.payload.elementType === 'button' ? 'BankId' : '',
      }
    ),
  [RX_LOGOUT]: () => ({
    event: 'log_out',
    event_info: {
      event_name: 'rx_log_out',
    },
  }),
});
