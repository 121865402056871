import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface LazyLoadingContextType {
  isVisible: boolean;
  onVisible: () => void;
}

const LazyLoadingContext = createContext<LazyLoadingContextType>({
  isVisible: false,
  onVisible: () => {
    throw new Error('No lazy loading context provided');
  },
});

export const useLazyLoading = () => useContext(LazyLoadingContext);

export const LazyLoadingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onVisible = useCallback(() => {
    setIsVisible(true);
  }, []);

  return (
    <LazyLoadingContext.Provider
      value={useMemo(() => ({ isVisible, onVisible }), [isVisible, onVisible])}
    >
      {children}
    </LazyLoadingContext.Provider>
  );
};
