import { Tracker } from '../../types';
import { accordionClicksTracker } from './handlers/accordionClicksTracker';
import { authenticationTracker } from './handlers/authenticationTracker';
import { brandPageTracker } from './handlers/brandPageTracker';
import { breadcrumbTracker } from './handlers/breadcrumbTracker';
import { buyWithPrescriptionTracker } from './handlers/buyWithPrescriptionTracker';
import { cartTracker } from './handlers/cartTracker';
import { checkoutTracker } from './handlers/checkoutTracker';
import { counselingTracker } from './handlers/counselingTracker';
import { expiredPrescriptionsToggleTracker } from './handlers/expiredPrescriptionsToggleTracker';
import { filterProductsTracker } from './handlers/filterProductsTracker';
import { findPharmacyTracker } from './handlers/findPharmacyTracker';
import { footerTracker } from './handlers/footerTracker';
import { headerTracker } from './handlers/headerTracker';
import { messageTracker } from './handlers/messageTracker';
import { miscTracker } from './handlers/miscTracker';
import { pageViewedTracker } from './handlers/pageViewedTracker';
import { prescriptionMenuTracker } from './handlers/prescriptionMenuTracker';
import { productClickedTracker } from './handlers/productClickedTracker';
import { productDetailTracker } from './handlers/productDetailTracker';
import { productImpressionTracker } from './handlers/productImpressionTracker';
import { productMonitoringTracker } from './handlers/productMonitoringTracker';
import { promotionTracker } from './handlers/promotionTracker';
import { purchaseTracker } from './handlers/purchaseTracker';
import { searchFlyoutQueryTracker, searchFlyoutTracker } from './handlers/searchFlyoutTracker';
import { showMoreSubstitutionsModalClicksTracker } from './handlers/showMoreSubstitutionsModalClicksTracker';
import { teenagerCopyViewedTracker } from './handlers/teenagerCopyViewedTracker';

export const TRACKERS: Tracker[] = [
  accordionClicksTracker,
  buyWithPrescriptionTracker,
  brandPageTracker,
  breadcrumbTracker,
  cartTracker,
  checkoutTracker,
  counselingTracker,
  expiredPrescriptionsToggleTracker,
  filterProductsTracker,
  footerTracker,
  headerTracker,
  authenticationTracker,
  miscTracker,
  pageViewedTracker,
  productClickedTracker,
  productImpressionTracker,
  productDetailTracker,
  promotionTracker,
  purchaseTracker,
  searchFlyoutTracker,
  searchFlyoutQueryTracker,
  showMoreSubstitutionsModalClicksTracker,
  findPharmacyTracker,
  productMonitoringTracker,
  messageTracker,
  prescriptionMenuTracker,
  teenagerCopyViewedTracker,
];
