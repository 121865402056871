import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

export const isServer = () => typeof window === 'undefined';

const isApp = (): boolean =>
  Boolean(
    !!Cookies.get('kronan-app-only') &&
      !isServer() &&
      window &&
      window.location &&
      window.location.href &&
      window.location.href.includes('external')
  );

export interface FieldError {
  field: string;
  error: string;
}

export interface ExpeditionErrorResponse {
  error: string;
  errorCode: string | null;
  details: {
    description: string;
    subtitle: string;
    title: string;
  };
  errorFields: FieldError[];
}

const isExpeditionErrorResponse = (
  response: unknown
): response is AxiosResponse<ExpeditionErrorResponse> => {
  return (
    Boolean((response as AxiosResponse)?.data?.error) &&
    Boolean((response as AxiosResponse)?.data?.details?.title)
  );
};

export class ExpeditionError extends Error {
  public details: ExpeditionErrorResponse['details'];
  public errorType: string;
  public errorCode: string | null;
  public errorFields: FieldError[];
  constructor(
    details: ExpeditionErrorResponse['details'],
    errorType: string,
    errorCode: string | null,
    errorFields: FieldError[]
  ) {
    super(details.title);
    this.details = details;
    this.errorType = errorType;
    this.errorCode = errorCode;
    this.errorFields = errorFields;
  }
}

export const convertToExpeditionError = (error: unknown) => {
  if (axios.isAxiosError(error) && isExpeditionErrorResponse(error.response)) {
    if (isApp() && error.response.data.errorCode === 'EXP_401') {
      return;
    }
    return new ExpeditionError(
      error.response.data.details,
      error.response.data.error,
      error.response.data.errorCode,
      error.response.data.errorFields
    );
  }

  return error;
};

export const isExpeditionError = (error: unknown): error is ExpeditionError => {
  return error instanceof ExpeditionError && Boolean(error.details);
};
