import { COLLAPSABLE_TOGGLED } from '@packages/ui';
import {
  FIND_PHARMACY_FILTER_CLEARED,
  FIND_PHARMACY_FILTER_SELECTED,
  FIND_PHARMACY_LIST_MAP_TOGGLED,
  FIND_PHARMACY_MAP_CLICKED,
  PHARMACIES_LINK_CLICKED,
  PHARMACIES_PHARAMCY_CARD_LINK_CLICKED,
  REGION_LINK_CLICKED,
} from '../../../../../../components/pharmacies/analytics';
import { Metadata } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({
  linkText,
  userInteraction,
}: {
  linkText: string;
  userInteraction?:
    | 'button_click'
    | 'link_click'
    | 'map_click'
    | 'open_dropdown'
    | 'close_dropdown';
}) => ({
  event: 'hitta_apotek',
  event_info: {
    event_name: 'hitta_apotek',
    link_text: linkText,
    user_interaction: userInteraction,
  },
});

const createFilterEvent = ({
  filterId,
  userInteraction,
  filterType,
  filterSelection,
  filterNumberOfProducts,
}: {
  filterId: 'map_view' | 'list_view';
  userInteraction: 'open_dropdown' | 'close_dropdown' | 'select' | 'remove_all_filters';
  filterType: string;
  filterSelection: string;
  filterNumberOfProducts: string;
}) => ({
  event: 'filter_apotek',
  event_info: {
    event_name: 'filter_apotek',
    filter_id: filterId,
    user_interaction: userInteraction,
    filter_type: filterType,
    filter_selection: filterSelection,
    filter_number_of_products: filterNumberOfProducts,
  },
});

const isFindPharmacyEvent = (metadata: Metadata) =>
  typeof metadata.origin === 'string' && metadata?.origin.includes('hitta_apotek');

const isPharmacyMapEvent = (metadata: Metadata) => metadata?.origin === 'hitta_apotek_map';

export const findPharmacyTracker = createSimpleTracker({
  [PHARMACIES_LINK_CLICKED]: ({ event, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return createBaseEvent({
        linkText: convertTextToSlug(event.payload.linkText),
        userInteraction: event.payload.userInteraction,
      });
    }
  },
  [PHARMACIES_PHARAMCY_CARD_LINK_CLICKED]: ({ event, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return createBaseEvent({
        linkText: convertTextToSlug(event.payload.linkText),
        userInteraction: isPharmacyMapEvent(metadata) ? 'map_click' : event.payload.userInteraction,
      });
    }
  },
  [FIND_PHARMACY_LIST_MAP_TOGGLED]: ({ event }) => {
    return createBaseEvent({
      linkText: convertTextToSlug(event.payload.linkText),
      userInteraction: event.payload.userInteraction,
    });
  },
  [FIND_PHARMACY_MAP_CLICKED]: ({ event }) => {
    return createBaseEvent({
      linkText: convertTextToSlug(event.payload.linkText),
      userInteraction: event.payload.userInteraction,
    });
  },
  [COLLAPSABLE_TOGGLED]: ({ event, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return createFilterEvent({
        filterId: isPharmacyMapEvent(metadata) ? 'map_view' : 'list_view',
        userInteraction: event.payload.isToggled ? 'open_dropdown' : 'close_dropdown',
        filterType: convertTextToSlug(event.payload.label),
        filterSelection: 'N/A',
        filterNumberOfProducts: 'N/A',
      });
    }
  },
  [FIND_PHARMACY_FILTER_SELECTED]: ({ event, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return createFilterEvent({
        filterId: isPharmacyMapEvent(metadata) ? 'map_view' : 'list_view',
        userInteraction: 'select',
        filterType: convertTextToSlug(event.payload.filterType),
        filterSelection: convertTextToSlug(event.payload.filterSelection.toLowerCase()),
        filterNumberOfProducts: String(event.payload.filterNumberOfProducts),
      });
    }
  },
  [FIND_PHARMACY_FILTER_CLEARED]: ({ event: _, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return createFilterEvent({
        filterId: isPharmacyMapEvent(metadata) ? 'map_view' : 'list_view',
        userInteraction: 'remove_all_filters',
        filterType: 'N/A',
        filterSelection: 'N/A',
        filterNumberOfProducts: 'N/A',
      });
    }
  },
  [REGION_LINK_CLICKED]: ({ event, metadata }) => {
    if (isFindPharmacyEvent(metadata)) {
      return {
        event: 'link_clicks',
        event_info: {
          event_name: 'link_clicks',
          user_interaction: 'click_apotek',
          text_title: 'Våra apotek i Sverige',
          link_text: event.payload.linkText,
        },
      };
    }
  },
});
