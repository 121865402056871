import { AnalyticsEvents as BreadcrumbsAnalyticsEvents } from './breadcrumbs/analytics';

export { BREADCRUMB_CLICKED } from './breadcrumbs/analytics';

export const COLLAPSABLE_TOGGLED = 'UI/COLLAPSABLE_TOGGLED';

export interface CollapsableToggled {
  type: typeof COLLAPSABLE_TOGGLED;
  payload: {
    isToggled: boolean;
    label: string;
  };
}

export type AnalyticsEvents = CollapsableToggled | BreadcrumbsAnalyticsEvents;
