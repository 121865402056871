import axios from 'axios';
import { Order, OrderReturn, ReturnOrderRows, ReturnReasons } from './types';

export const createApiClient = (baseUrl: string) => {
  const axiosClient = axios.create({
    baseURL: baseUrl,
  });

  const getCustomerTokenHeaders = (customerToken?: string) => {
    if (customerToken) {
      return {
        headers: {
          'customer-token': customerToken,
        },
      };
    }
  };

  return {
    getOrder: async (orderId: string, customerToken?: string) => {
      const response = await axiosClient.get<Order>(
        `/order/${orderId}`,
        getCustomerTokenHeaders(customerToken)
      );
      return response.data;
    },

    getOrderByCartId: async (cartId: string, customerToken?: string) => {
      const response = await axiosClient.get<Order>(
        `/order/cart/${cartId}`,
        getCustomerTokenHeaders(customerToken)
      );
      return response.data;
    },

    getOrderReturn: async (orderId: string, customerToken: string) => {
      const response = await axiosClient.get<OrderReturn>(
        `/return/orders/${orderId}`,
        getCustomerTokenHeaders(customerToken)
      );
      return response.data;
    },

    getReturnReasons: async () => {
      const response = await axiosClient.get<ReturnReasons[]>(`/return/returnreasons`);
      return response.data;
    },

    createOrderReturn: async (
      orderId: string,
      customerToken: string,
      orderRowsToReturn: ReturnOrderRows
    ) => {
      const response = await axiosClient.post<ReturnOrderRows>(
        `/return/orders/${orderId}`,
        orderRowsToReturn,
        getCustomerTokenHeaders(customerToken)
      );
      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
