import { ItemRestriction } from '../..';

export class RestrictionError extends Error {
  public details: ItemRestriction;

  constructor(message: string, details: ItemRestriction) {
    super(message);
    this.message = message;
    this.name = 'RestrictionError';
    this.details = details;
  }
}
