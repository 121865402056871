import React, { createContext, useCallback, useContext, useState } from 'react';

export interface FeatureFlagsContextType {
  isFeatureFlagEnabled: (flag: string) => boolean;
  overrideFeatureFlag: (flag: string, isEnabled: boolean) => void;
  allFlags: Record<string, boolean>;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  isFeatureFlagEnabled: () => false,
  overrideFeatureFlag: () => {},
  allFlags: {},
});

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const useFeatureEnabled = (flag: string) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();

  return isFeatureFlagEnabled(flag);
};

interface Props {
  initialFlags: Record<string, boolean>;
}

export const FeatureFlagsProvider: React.FC<React.PropsWithChildren<Props>> = ({
  initialFlags,
  children,
}) => {
  const [flags, setFlags] = useState(initialFlags);

  const overrideFeatureFlag = useCallback(
    (flag: string, isEnabled: boolean) => {
      setFlags((currentFlags) => ({
        ...currentFlags,
        [flag]: isEnabled,
      }));
    },
    [setFlags]
  );

  const contextValue: FeatureFlagsContextType = {
    isFeatureFlagEnabled: (flag: string) => {
      return !!flags[flag];
    },
    overrideFeatureFlag,
    allFlags: flags,
  };

  return (
    <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>
  );
};
