import { getValueFromStorage, setValueToStorage, removeKeyFromStorage } from '@packages/utils';

export interface StorageValue {
  value: boolean;
  expiryTime: number;
}

export const setRxStorageValueWithExpiry = (key: string, value: boolean) => {
  const now = new Date();
  const expiryTime = now.getTime() + 24 * 60 * 60 * 1000;
  const storageValue = {
    value: value,
    expiryTime: expiryTime,
  };
  setValueToStorage<StorageValue>(key, storageValue);
};

export const getRxStorageValueWithExpiry = (key: string) => {
  const storageValue = getValueFromStorage<StorageValue>(key);
  if (!storageValue) return null;
  const now = new Date();
  if (now.getTime() > storageValue.expiryTime) {
    removeKeyFromStorage(key);
    return null;
  }
  return storageValue.value;
};
