import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

export const useNavigationState = <T, K extends string = string>(
  stateName: K,
  defaultValue: T
): [T, (value: T) => void] => {
  const { state } = useLocation() as {
    state?: {
      [key in K]: T;
    };
  };

  const setState = (newState: T) => {
    const currentScroll = window.scrollY;
    navigate(window.location.pathname, {
      replace: true,
      state: {
        ...state,
        [stateName]: newState,
      },
    }).then(() => {
      window.scrollTo(0, currentScroll);
    });
  };

  return [state?.[stateName] ?? defaultValue, setState];
};
