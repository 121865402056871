import { MEDICAL_COUNSELING_PREFERENCE_CHOSEN } from '../../../../../../checkout/steps/MedicalCounseling/analytics';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const counselingTracker = createSimpleTracker({
  [MEDICAL_COUNSELING_PREFERENCE_CHOSEN]: ({ event: { payload } }) => {
    return {
      event: 'rådgivning',
      event_info: {
        event_name: 'rådgivning',
        link_text: convertTextToSlug(payload.label),
      },
    };
  },
});
