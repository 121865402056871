import { isServer } from '@packages/gatsby-utils';
import React, { createContext, useEffect, useMemo, useState } from 'react';

const Context = createContext<string | null>(null);

export const usePreviousLocation = () => React.useContext(Context);

export const PREVIOUS_PATH_KEY = '@@previousPath';
export const PreviousLocationProvider: React.FC<React.PropsWithChildren<{ pathname: string }>> = ({
  children,
  pathname,
}) => {
  const isBrowser = !isServer();

  const [location, setLocation] = useState<string | null>(
    isBrowser ? window.sessionStorage.getItem(PREVIOUS_PATH_KEY) : null
  );

  useEffect(() => {
    if (isBrowser) {
      setLocation(window.sessionStorage.getItem(PREVIOUS_PATH_KEY));
    }
  }, [isBrowser, pathname]);

  const contextValue = useMemo(() => location, [location]);
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
