import { CollapsableToggled, COLLAPSABLE_TOGGLED } from '@packages/ui';
import { EXPANDABLE_CONTENT_ON_TOGGLE } from '../../../../../../components/expedition/prescriptions/buyableOptions/analytics';
import { Tracker } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const trackCollapsableToggled = ({ event }: { event: CollapsableToggled }) => {
  return {
    event: 'accordion_clicks',
    event_info: {
      event_name: 'accordion_clicks',
      user_interaction: event.payload.isToggled ? 'expand' : 'close',
      link_text: convertTextToSlug(event.payload.label),
    },
  };
};

export const accordionClicksTracker: Tracker = createSimpleTracker({
  [COLLAPSABLE_TOGGLED]: trackCollapsableToggled,
  [EXPANDABLE_CONTENT_ON_TOGGLE]: ({ event }) => ({
    event: 'expandable_content_clicks',
    event_info: {
      event_name: 'expandable_content_clicks',
      user_interaction: event.payload.isOpen ? 'expand' : 'close',
      link_text: convertTextToSlug(event.payload.label),
    },
  }),
});
