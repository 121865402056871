export const ARTICLE_BLOCK_CLICKED = 'ARTICLE_BLOCK_CLICKED';

type Payload = {
  id: string;
  position: number;
};

export interface ArticleBlockClicked {
  type: typeof ARTICLE_BLOCK_CLICKED;
  payload: Payload;
}

export const ARTICLE_BLOCK_VIEWED = 'ARTICLE_BLOCK_VIEWED';

export interface ArticleBlockViewed {
  type: typeof ARTICLE_BLOCK_VIEWED;
  payload: Payload;
}

export type AnalyticsEvents = ArticleBlockClicked | ArticleBlockViewed;
