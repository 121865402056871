import { LINK_BLOCK_CLICKED } from '../../../../../../../src/components/blocks/linksBlock/analytics';
import {
  CONTENT_PAGE_CATEGORY_LINK_CLICKED,
  CONTENT_PAGE_GO_BACK_LINK_CLICKED,
  CONTENT_PAGE_MOBILE_MENU_CLOSED,
  CONTENT_PAGE_MOBILE_MENU_OPENED,
  CONTENT_PAGE_SUBITEM_LINK_CLICKED,
} from '../../../../../../../src/components/pages/analytics';
import { ARTICLE_LINK_CLICKED } from '../../.././../../../../src/components/articleList/analytics';
import { Metadata } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({
  userInteraction,
  navigationSelection,
}: {
  userInteraction: string;
  navigationSelection?: string;
  eventName?: string;
}) => ({
  event: 'brand_menu',
  event_info: {
    event_name: 'brand_menu',
    user_interaction: userInteraction,
    navigation_selection: navigationSelection ? convertTextToSlug(navigationSelection) : 'N/A',
  },
});

const isBrandPageEvent = (metadata: Metadata) => metadata?.origin === 'brand';

export const brandPageTracker = createSimpleTracker({
  [CONTENT_PAGE_MOBILE_MENU_OPENED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'open_menu',
        navigationSelection: event.payload.id,
      });
    }
  },
  [CONTENT_PAGE_MOBILE_MENU_CLOSED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'close_menu',
        navigationSelection: event.payload.id,
      });
    }
  },
  [CONTENT_PAGE_GO_BACK_LINK_CLICKED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'back_to_brand',
        navigationSelection: event.payload.id,
      });
    }
  },

  [CONTENT_PAGE_CATEGORY_LINK_CLICKED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'link_click',
        navigationSelection: event.payload.id,
      });
    }
  },
  [CONTENT_PAGE_SUBITEM_LINK_CLICKED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'link_click',
        navigationSelection: event.payload.id,
      });
    }
  },
  [ARTICLE_LINK_CLICKED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return {
        event: 'link_clicks',
        event_info: {
          event_name: 'link_clicks',
          user_interaction: 'click_article',
          text_title: convertTextToSlug(event.payload.textTitle),
          link_text: convertTextToSlug(event.payload.linkText),
        },
      };
    }
  },
  [LINK_BLOCK_CLICKED]: ({ event, metadata }) => {
    if (isBrandPageEvent(metadata)) {
      return {
        event: 'link_clicks',
        event_info: {
          event_name: 'link_clicks',
          user_interaction: 'click_category',
          text_title: convertTextToSlug(event.payload.textTitle),
          link_text: convertTextToSlug(event.payload.linkText),
        },
      };
    }
  },
});
