export const MEDICAL_COUNSELING_PREFERENCE_CHOSEN = 'RX/MEDICAL_COUNSELING_PREFERENCE_CHOSEN';

export interface MedicalCounselingPreferenceChosen {
  type: typeof MEDICAL_COUNSELING_PREFERENCE_CHOSEN;
  payload: {
    wantsCounseling: boolean;
    label: string;
  };
}

export type AnalyticsEvents = MedicalCounselingPreferenceChosen;
