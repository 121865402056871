import { Product } from '../../types';

export const PRODUCT_PAGE_VIEWED = 'PRODUCT_PAGE/VIEWED';

export interface ProductPageViewed {
  type: typeof PRODUCT_PAGE_VIEWED;
  payload: {
    product: Product;
  };
}

export const PRODUCT_PAGE_CART_CHANGE = 'PRODUCT_PAGE/CART_CHANGE';

export interface ProductPageCartChange {
  type: typeof PRODUCT_PAGE_CART_CHANGE;
  payload: {
    product: Product;
    previousQuantity: number;
    quantity: number;
  };
}

export const PRODUCT_PAGE_BUY_WITH_PRESCRIPTION_CLICKED =
  'PRODUCT_PAGE/BUY_WITH_PRESCRIPTION_CLICKED';

export interface ProductPageBuyWithPrescriptionClicked {
  type: typeof PRODUCT_PAGE_BUY_WITH_PRESCRIPTION_CLICKED;
}

export const PRODUCT_PAGE_FIND_IN_PHARMACY_MODAL_OPENED =
  'PRODUCT_PAGE/FIND_IN_PHARMACY_MODAL_OPENED';

export interface ProductPageFindInPharmacyModalOpened {
  type: typeof PRODUCT_PAGE_FIND_IN_PHARMACY_MODAL_OPENED;
  payload: {
    linkText: string;
  };
}

export const PRODUCT_MONITORING_POSTED = 'PRODUCT_MONITORING/POSTED';
export interface ProductMonitoringSuccess {
  type: typeof PRODUCT_MONITORING_POSTED;
  payload: {
    formIsPosted: boolean;
  };
}

export type AnalyticsEvents =
  | ProductPageViewed
  | ProductPageCartChange
  | ProductPageBuyWithPrescriptionClicked
  | ProductPageFindInPharmacyModalOpened
  | ProductMonitoringSuccess;
