export const LINK_CLICKED = 'FOOTER/LINK_CLICKED';

export interface LinkClicked {
  type: typeof LINK_CLICKED;
  payload: {
    id: string;
  };
}

export type AnalyticsEvents = LinkClicked;
