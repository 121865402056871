import axios from 'axios';
import { Pharmacy, PharmacyResponse, UserPurchase, UserPurchaseRow } from './types';

export interface Position {
  lat: number;
  lng: number;
}

export const createApiClient = (baseUrl = '/api/bizniz') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getAxiosInstance: () => client,
    searchPharmacies: async ({
      query,
      productIds,
      page,
      languages,
      bookableServices,
      locationServices,
      productsIds,
      inStockOnly,
      prescriptionIdParams,
      pharmacies,
    }: {
      query: string | Position;
      productIds?: string[] | null;
      page?: number | null;
      languages?: number[];
      bookableServices?: number[];
      locationServices?: number[];
      productsIds?: string[];
      inStockOnly?: boolean;
      prescriptionIdParams?: string | null;
      pharmacies?: string[];
    }) => {
      const params = {
        page: `${page || 0}`,
        pageSize: '10',
        products: productIds?.join(','),
        location: typeof query === 'string' && query.length > 0 ? query : undefined,
        originLatitude: typeof query === 'object' && 'lat' in query ? `${query.lat}` : undefined,
        originLongitude: typeof query === 'object' && 'lng' in query ? `${query.lng}` : undefined,
        languages: languages?.join(','),
        bookableServices: bookableServices?.join(','),
        locationServices: locationServices?.join(','),
        productsIds: productsIds?.join(','),
        option: inStockOnly ? 'inStockOnly' : undefined,
        prescriptionId: prescriptionIdParams,
        pharmacy: pharmacies?.join(','),
      };

      const response = await client
        .get<PharmacyResponse>('/public/pharmacy/search', {
          params,
        })
        .then((response) => {
          const data = response.data;

          if (!data?.pharmacies)
            return {
              pharmacies: [],
              totalPages: 0,
              pageNumber: 0,
              pageSize: 10,
              first: true,
              last: true,
              empty: true,
            };
          const productAvailability = data.productAvailability
            ? Object.fromEntries(data.productAvailability?.results.map((pa) => [pa.id, pa]))
            : null;
          const pharmacies = data.pharmacies.map(({ pharmacy, distance }) => {
            return {
              ...pharmacy,
              productAvailability: productAvailability?.[pharmacy.id],
              distance,
            };
          });
          return {
            pharmacies,
            bookableServiceFilter: data.bookableServiceFilter,
            locationServiceFilter: data.locationServiceFilter,
            languageFilter: data.languageFilter,
            totalPages: data.totalPages,
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            first: data.first,
            last: data.last,
            empty: data.empty,
          };
        });

      return response;
    },
    getPharmacyLocations: async () => {
      const response = await client.get<Pharmacy[]>('/public/pharmacy/location/search');
      return response.data;
    },
    getPharmacySuggestions: async (search: string) => {
      const response = await client.get<string[] | null>(`/public/location/suggest/${search}`);
      return response.data;
    },
    productHasSearchRestrictions: async (itemId: string) => {
      const response = await client.get<string>(`/public/restriction/item/${itemId}`);
      return response.data;
    },
    getPurchases: async () => {
      const response = await client.get<UserPurchase[]>('/protected/mypages/purchases');
      return response.data;
    },
    getPurchaseDetails: async (id: string) => {
      const response = await client.get<UserPurchaseRow[]>(
        `/protected/mypages/purchases/rows/${id}`
      );
      return response.data;
    },
  };
};
export type ApiClient = ReturnType<typeof createApiClient>;
