import {
  ALERT_BUTTON_CLICK,
  ALERT_SHOWN,
} from '@oriola-corporation/ui-components/lib/esm/components/Alert/analytics';
import {
  DETAILED_ALERT_BUTTON_CLICK,
  DETAILED_ALERT_SHOWN,
} from '@oriola-corporation/ui-components/lib/esm/components/DetailedAlert/analytics';
import {
  MODAL_OPENED,
  MODAL_BUTTON_CLICK,
} from '@oriola-corporation/ui-components/lib/esm/components/Modal/analytics';

import { Tracker } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({
  eventName,
  textTitle,
  navigationSelection,
  userInteraction,
}: {
  eventName: string;
  textTitle: string;
  navigationSelection?: string;
  userInteraction?: 'link_click';
}) => ({
  event: 'message',
  event_info: {
    event_name: eventName,
    text_title: textTitle,
    ...(navigationSelection && { navigation_selection: navigationSelection }),
    ...(userInteraction && { user_interaction: userInteraction }),
  },
});

export const messageTracker: Tracker = createSimpleTracker({
  [MODAL_OPENED]: ({ event }) =>
    createBaseEvent({
      eventName: 'modal_message_impression',
      textTitle: event.payload.title,
    }),
  [MODAL_BUTTON_CLICK]: ({ event }) =>
    createBaseEvent({
      eventName: 'modal_button_click',
      userInteraction: 'link_click',
      navigationSelection: convertTextToSlug(event.payload.actionLabel),
      textTitle: event.payload.title,
    }),

  [ALERT_SHOWN]: ({ event }) =>
    createBaseEvent({
      eventName: 'alert_message_impression',
      textTitle: event.payload.title,
    }),
  [ALERT_BUTTON_CLICK]: ({ event }) =>
    createBaseEvent({
      eventName: 'alert_button_click',
      textTitle: event.payload.title,
      navigationSelection: convertTextToSlug(event.payload.actionLabel),
      userInteraction: 'link_click',
    }),
  [DETAILED_ALERT_BUTTON_CLICK]: ({ event }) =>
    createBaseEvent({
      eventName: 'alert_button_click',
      textTitle: event.payload.title,
      navigationSelection: convertTextToSlug(event.payload.actionLabel),
      userInteraction: 'link_click',
    }),
  [DETAILED_ALERT_SHOWN]: ({ event }) =>
    createBaseEvent({
      eventName: 'alert_message_impression',
      textTitle: event.payload.title,
    }),
});
