export enum PostMessage {
  CART_CHANGE = 'CART_CHANGE',
  KLARNA_HEIGHT = 'KLARNA_HEIGHT',
  INGRID_HEIGHT = 'INGRID_HEIGHT',
  PDF_DOWNLOAD = 'PDF_DOWNLOAD',
  VIEW_CHECKOUT = 'VIEW_CHECKOUT',
  ANALYTICS_EVENT = 'ANALYTICS_EVENT',
  VIEW_PDF = 'VIEW_PDF',
  FIND_PHARMACY = 'FIND_PHARMACY',
  TOKEN_ERROR = 'TOKEN_ERROR',
}
