import { PRODUCT_PAGE_BUY_WITH_PRESCRIPTION_CLICKED } from '../../../../../../components/pages/productPage/analytics';

import { createSimpleTracker } from '../utils/createSimpleTracker';

export const buyWithPrescriptionTracker = createSimpleTracker({
  [PRODUCT_PAGE_BUY_WITH_PRESCRIPTION_CLICKED]: () => ({
    event: 'köp_via_ditt_recept',
    payload: {
      event_name: 'köp_via_ditt_recept',
      link_type: 'button',
    },
  }),
});
