import axios from 'axios';
import { Restriction } from './types';

export const createApiClient = (baseUrl = '/item-sales-restriction/external/restrictions') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getRestrictionsForProduct: async (productIds: string[]) => {
      if (productIds.length === 0) return Promise.resolve([]);
      const response = await client.post<Restriction[]>(`/disclosure/item`, productIds);

      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
