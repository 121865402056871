export class InvalidVoucherError extends Error {
  public reason:
    | 'NO_LONGER_VALID'
    | 'NOT_APPLICABLE'
    | Omit<string, 'NO_LONGER_VALID' | 'NOT_APPLICABLE'>;

  constructor(message: string, reason: string) {
    super(message);
    this.message = message;
    this.name = 'InvalidVoucherError';
    this.reason = reason;
  }
}
