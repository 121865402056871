interface Breadcrumb {
  url: string;
  name: string;
}

export const BREADCRUMB_CLICKED = 'BREADCRUMBS/BREADCRUMB_CLICKED';

export interface BreadcrumbClicked {
  type: typeof BREADCRUMB_CLICKED;
  payload: {
    breadcrumb: Breadcrumb;
    all: Breadcrumb[];
  };
}

export type AnalyticsEvents = BreadcrumbClicked;
