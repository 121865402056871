import {
  ShowMoreSubstitutionsModalToggled,
  SHOW_MORE_SUBSTITUTIONS_MODAL_TOGGLED,
} from '../../../../../../components/expedition/prescriptions/buyableOptions/analytics';
import { Tracker } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const showMoreSubstitutionsModalClicksTracker: Tracker = createSimpleTracker({
  [SHOW_MORE_SUBSTITUTIONS_MODAL_TOGGLED]: ({
    event,
  }: {
    event: ShowMoreSubstitutionsModalToggled;
  }) => ({
    event: 'visa_utbyten',
    event_info: {
      event_name: 'visa_utbyten',
      user_interaction: event.payload.isOpen ? 'open_modal' : 'close_modal',
    },
  }),
});
