import { Restriction } from '@apis/restrictions';
import { createBatchLoaderContext } from '@oriola-corporation/ui-components';
import { useApi } from '@packages/api';
import React from 'react';
import ReactDOM from 'react-dom';

const { Provider: BatchProvider, useItem } = createBatchLoaderContext<Restriction>(
  (restriction) => restriction.itemId,
  ReactDOM.unstable_batchedUpdates
);

export const ProductRestrictionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { restriction } = useApi();

  return (
    <BatchProvider loadItems={restriction.getRestrictionsForProduct} debounce={100}>
      {children}
    </BatchProvider>
  );
};

export const useProductRestriction = (itemId: string) => {
  const { data, isLoading, hasErrors } = useItem(itemId);

  return {
    data,
    isLoading,
    hasErrors,
  };
};
