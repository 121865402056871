import {
  LINK_CLICKED,
  MOBILE_MENU_TOGGLED,
} from '../../../../../../components/layout/header/analytics';
import {
  FLYOUT_LINK_CLICKED,
  SUBMENU_TOGGLED,
} from '../../../../../../components/layout/header/flyout/analytics';
import { Metadata, Tracker } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({
  userInteraction,
  navigationSelection,
}: {
  userInteraction: string;
  navigationSelection?: string;
}) => ({
  event: 'main_menu',
  event_info: {
    event_name: 'main_menu',
    user_interaction: userInteraction,
    navigation_selection: navigationSelection ? convertTextToSlug(navigationSelection) : 'N/A',
  },
});

const isHeaderFlyoutEvent = (metadata: Metadata) => metadata?.origin === 'header';

export const headerTracker: Tracker = createSimpleTracker({
  [MOBILE_MENU_TOGGLED]: ({ event }) =>
    createBaseEvent({
      userInteraction: event.payload.isOpen ? 'open_menu' : 'close_menu',
      navigationSelection: event.payload.id || 'main',
    }),
  [LINK_CLICKED]: ({ event }) =>
    createBaseEvent({
      userInteraction: 'link_click',
      navigationSelection: event.payload.id,
    }),
  [SUBMENU_TOGGLED]: ({ event, metadata }) => {
    if (isHeaderFlyoutEvent(metadata)) {
      return createBaseEvent({
        userInteraction: event.payload.isOpen ? 'dropdown_open' : 'dropdown_close',
        navigationSelection: event.payload.id,
      });
    }
  },
  [FLYOUT_LINK_CLICKED]: ({ event, metadata }) => {
    if (isHeaderFlyoutEvent(metadata)) {
      return createBaseEvent({
        userInteraction: 'link_click',
        navigationSelection: event.payload.id,
      });
    }
  },
});
