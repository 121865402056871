import { AnalyticsEvents, Metadata, Tracker, TrackingServices } from '../../../types';
import { trackEvent } from './trackEvent';

type UnknownObject = Record<string, unknown>;

type SimpleTracker<E> = (
  params: {
    event: E;
    metadata: Metadata;
  },
  services: TrackingServices
) =>
  | UnknownObject
  | UnknownObject[]
  | undefined
  | void
  | Promise<undefined>
  | Promise<UnknownObject | UnknownObject[] | undefined | void>;

type EventType = AnalyticsEvents['type'];

type EventOfType<T extends EventType> = Extract<AnalyticsEvents, { type: T }>;

type TrackerConfig<K extends EventType> = Partial<{
  [Key in K]: SimpleTracker<EventOfType<Key>>;
}>;

const isPromise = (
  obj:
    | UnknownObject
    | UnknownObject[]
    | void
    | Promise<UnknownObject | UnknownObject[] | undefined | void>
): obj is Promise<UnknownObject> | Promise<void> => {
  return !!(obj as Promise<unknown>)?.then && typeof (obj as Promise<unknown>).then === 'function';
};

const getTrackerForType = <T extends EventType>(
  config: TrackerConfig<EventType>,
  type: T
): SimpleTracker<EventOfType<T>> | undefined => {
  return config[type] as SimpleTracker<EventOfType<T>> | undefined;
};

const sendEvent = (data: unknown | unknown[]) => {
  if (Array.isArray(data)) {
    data.forEach((event) => {
      trackEvent(event);
    });
  } else {
    trackEvent(data);
  }
};

export const createSimpleTracker =
  (config: TrackerConfig<EventType>): Tracker =>
  async ({ event, metadata }, services) => {
    const tracker = getTrackerForType(config, event.type);
    if (tracker) {
      try {
        const result = tracker({ event, metadata }, services);

        if (result) {
          if (isPromise(result)) {
            return result.then((data) => {
              if (data) {
                sendEvent(data);
              }
            });
          }

          return sendEvent(result);
        }
      } catch (e) {
        console.warn(`Error occurred when tracking event ${event.type}`, e);
      }
    }
  };
