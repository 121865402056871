import axios from 'axios';
import type { ServiceMessage } from './types';

export const createApiClient = (baseUrl: string) => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getMessages: async () => {
      const response = await client.get<ServiceMessage[]>('/');

      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
