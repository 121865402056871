import { useAsyncSource } from '@oriola-corporation/kronan-hooks';
import { COLORS, Spinner, mediaQueries } from '@oriola-corporation/ui-components';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import axios from 'axios';
import clsx from 'clsx';
import cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { HiLink } from 'react-icons/hi';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

const SUPPORTS_MULTIPLE_FEATURES = getConfigurationVariable('SUPPORTS_FEATURE_BUILDS') === 'true';

export const FeatureSwitcher: React.FC = () => {
  if (!SUPPORTS_MULTIPLE_FEATURES) {
    return null;
  }

  return <FeatureToggler />;
};

const DEFAULT_COLOR = '#fdddb8';
const FEATURE_URL_QUERY_PARAM = 'kronanFeature';
const COOKIE_NAME = 'kronan-feature';

const fetchActiveFeatures = async () => {
  const response =
    await axios.get<Array<{ name: string; color: string; owner: string }>>('/features.json');

  return response.data;
};

const FeatureToggler: React.FC = () => {
  const listRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [copiedText, setCopiedText] = useState('');
  const [selectedFeature, setSelectedFeature] = useState('');

  const { data: features, isFetching } = useAsyncSource(fetchActiveFeatures);

  const copyLink = async ({ text }: { text: string }) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText('');
      }, 2000);
    } catch (e) {
      console.log('Länk kopierades inte...');
      throw e;
    }
  };

  useEffect(() => {
    const currentFeature = cookies.get(COOKIE_NAME);
    if (currentFeature) {
      setSelectedFeature(currentFeature);
    }
  }, []);

  const activeFeature = (features || [])?.find((feature) => feature.name === selectedFeature);
  useOnClickOutside(listRef, () => setIsExpanded(false));

  const branchUrl = (branchName: string) => {
    const urlObj = new URL(window.location.href);
    if (branchName === 'default') {
      urlObj.searchParams.delete(FEATURE_URL_QUERY_PARAM);
    } else {
      urlObj.searchParams.set(FEATURE_URL_QUERY_PARAM, branchName);
    }
    return !isServer() ? urlObj.href : '';
  };

  return (
    <div ref={listRef} className="relative">
      {features && (
        <Dot
          onClick={() => setIsExpanded((s) => !s)}
          backgroundColor={activeFeature?.color || DEFAULT_COLOR}
        />
      )}
      {isExpanded && (
        <FeatureList>
          <Feature>
            <a
              href={branchUrl('default')}
              onClick={() => cookies.remove(COOKIE_NAME)}
              className={clsx('flex w-full items-center gap-2 p-1 hover:bg-gray-100', {
                'bg-gray-100': selectedFeature === '',
              })}
            >
              <Indicator />
              <p className="font-default">Default</p>
            </a>
          </Feature>
          {isFetching && <Spinner />}
          {features &&
            features.map((branch) => (
              <Feature key={branch.name}>
                <button
                  title={`Kopiera länk till ${branch.name}`}
                  onClick={() => copyLink({ text: branchUrl(branch.name) })}
                  className="p-2 hover:rounded-full hover:bg-gray-100"
                >
                  <HiLink />
                </button>

                <a
                  href={branchUrl(branch.name)}
                  className={clsx(
                    'flex w-full items-center gap-2 p-1 hover:rounded-lg hover:bg-gray-100',
                    {
                      'bg-gray-100 rounded-lg': selectedFeature === branch.name,
                    }
                  )}
                >
                  <Indicator backgroundColor={branch.color} />
                  <div className="flex flex-col items-start md:flex-row md:gap-2">
                    {copiedText.includes(branchUrl(branch.name)) ? (
                      <p>Länk är kopierad</p>
                    ) : (
                      <p className="font-default">{branch.name}</p>
                    )}
                    <p className="text-gray-500 md:ml-auto">{branch.owner}</p>
                  </div>
                </a>
              </Feature>
            ))}
        </FeatureList>
      )}
    </div>
  );
};

const Dot = styled.button<{ backgroundColor: string }>`
  position: fixed;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 10px;
  z-index: 100000;
  background: ${(props) => props.backgroundColor};
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgb(63 63 68 / 10%);
`;

const FeatureList = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 10px 10px 10px 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px 0 rgb(63 63 68 / 10%);
  border-radius: 5px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.PRIMARY};
  white-space: nowrap;
  margin-top: 30px;
  max-height: 700px;
  overflow-y: scroll;

  ${mediaQueries({
    medium: css`
      margin-left: 20px;
      max-height: unset;
    `,
  })}
`;

const Feature = styled.div<{ isActive?: boolean }>`
  position: relative;
  font-size: 17px;
  display: flex;
  align-items: center;
  ${(props) => (props.isActive ? 'background-color: #c8c8c8;' : '')}
  gap: 5px;
`;

const Indicator = styled.div<{ backgroundColor?: string }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor || DEFAULT_COLOR};
  margin-right: 5px;
`;
