import {
  BANNER_BOX_CLICKED,
  BANNER_BOX_VIEWED,
} from '../../../../../../components/banners/analytics';
import {
  ARTICLE_BLOCK_CLICKED,
  ARTICLE_BLOCK_VIEWED,
} from '../../../../../../components/blocks/articleBlock/analytics';
import {
  CALENDAR_CARD_CLICKED,
  CALENDAR_CARD_VIEWED,
} from '../../../../../../components/blocks/calendarBlock/analytics';
import { Size } from '../../../../../../components/blocks/campaignListBlock';
import { HERO_CLICKED, HERO_VIEWED } from '../../../../../../components/hero/analytics';
import { BannerBox, BannerBoxSource } from '../../../../../../components/types';
import { Tracker } from '../../../types';
import { trackEvent } from '../utils/trackEvent';

interface BannerTrackingInfo {
  listType: string;
  banner: BannerBox;
  position: number;
  size: Size | 'fullWidth';
  type: BannerBoxSource;
}

interface HeroTrackingInfo {
  listType: string;
  title: string;
  position: number;
  size: Size | 'fullWidth';
  type: BannerBoxSource;
}

export interface PromoEvent {
  id: string;
  name: 'full_width_banner' | `${Size}_campaign_card` | 'Calendar_campaign_card' | 'article_banner';
  position: number;
  creative: string;
  type?: string;
}

const formatHero = ({ title, size, position, listType, type }: HeroTrackingInfo): PromoEvent => ({
  id: title,
  position,
  name: size === 'fullWidth' ? 'full_width_banner' : (`${size}_campaign_card` as const),
  creative: listType === 'listBlock' ? 'Static' : listType,
  type: type?.toLowerCase(),
});

const formatBanner = ({
  banner,
  size,
  position,
  listType,
  type,
}: BannerTrackingInfo): PromoEvent => ({
  id: banner.title,
  position,
  name: size === 'fullWidth' ? 'full_width_banner' : (`${size}_campaign_card` as const),
  creative: listType === 'listBlock' ? 'Static' : listType,
  type: type?.toLowerCase(),
});

let queue: PromoEvent[] = [];

let debounceTimeout: ReturnType<typeof setTimeout> | null = null;

const scheduleTracking = () => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }

  debounceTimeout = setTimeout(() => {
    const currentQueue = queue;
    queue = [];
    trackEvent({
      event: 'eec.promotionView',
      ecommerce: {
        promoView: {
          promotions: currentQueue,
        },
      },
      _clear: true,
    });

    debounceTimeout = null;
  }, 200);
};

const trackPromoView = (event: PromoEvent) => {
  queue.push(event);
  scheduleTracking();
};

const trackPromoClicked = (event: PromoEvent, status?: string) => {
  const tmpEvent: Record<string, unknown> = {
    event: 'eec.promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [event],
      },
    },
  };

  if (status) {
    tmpEvent.status = status;
  }

  trackEvent(tmpEvent);
};

export const promotionTracker: Tracker = ({ event, metadata }) => {
  switch (event.type) {
    case ARTICLE_BLOCK_CLICKED: {
      const { id, position } = event.payload;
      return trackPromoClicked({
        id: id,
        position: position,
        name: 'article_banner',
        creative: 'Static',
      });
    }
    case ARTICLE_BLOCK_VIEWED: {
      const { id, position } = event.payload;
      return trackPromoView({
        id: id,
        position: position,
        name: 'article_banner',
        creative: 'Static',
      });
    }
    case CALENDAR_CARD_CLICKED: {
      const { status, heading, position } = event.payload;
      return trackPromoClicked(
        {
          id: heading,
          position: position,
          name: 'Calendar_campaign_card',
          creative: 'Static',
        },
        status
      );
    }
    case CALENDAR_CARD_VIEWED: {
      const { heading, position } = event.payload;
      return trackPromoView({
        id: heading,
        position: position,
        name: 'Calendar_campaign_card',
        creative: 'Static',
      });
    }
    case HERO_CLICKED: {
      const { title, position, isCarousel, type } = event.payload;

      return trackPromoClicked(
        formatHero({
          title,
          position,
          listType: isCarousel ? 'Carousel' : 'Static',
          size: 'fullWidth',
          type,
        })
      );
    }
    case HERO_VIEWED: {
      const { title, position, isCarousel, type } = event.payload;
      return trackPromoView(
        formatHero({
          title,
          position,
          listType: isCarousel ? 'Carousel' : 'Static',
          size: 'fullWidth',
          type,
        })
      );
    }
    case BANNER_BOX_CLICKED: {
      const { banner, position, size, type } = event.payload;
      return trackPromoClicked(
        formatBanner({
          banner,
          position,
          listType: (metadata.campaignListType as string) || 'N/A',
          size,
          type,
        })
      );
    }
    case BANNER_BOX_VIEWED: {
      const { banner, position, size, type } = event.payload;
      return trackPromoView(
        formatBanner({
          banner,
          position,
          listType: (metadata.campaignListType as string) || 'N/A',
          size,
          type,
        })
      );
    }
  }
};
