import React, { useEffect, useState } from 'react';

export const useSynchronizedLocalStorage = <T>(
  key: string,
  initialValue?: T
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
  const initialState = initialValue ?? localStorage.getItem(key);
  const [value, setValue] = useState<T>(initialState as T);

  useEffect(() => {
    if (value === null) {
      return localStorage.removeItem(key);
    }
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value as T);
  }, [key, value]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        if (!e.newValue) return;
        if (e.newValue === 'null') {
          localStorage.removeItem(key);
        } else {
          if (e.newValue) {
            try {
              const json = JSON.parse(e.newValue);
              setValue(json);
            } catch (_error) {
              setValue(e.newValue as T);
            }
          }
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const clear = () => {
    localStorage.removeItem(key);
  };

  return [value, setValue, clear];
};
