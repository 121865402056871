import { isServer } from '@packages/gatsby-utils';
import { Script, ScriptStrategy } from 'gatsby';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AggregatedRatingSchema, TestFreaksApi, TestFreaksElement } from './types';

interface TestFreaksContextValue {
  load: (elements: TestFreaksElement[]) => void;
  setProductId: (productId: string) => void;
  setFamilyId: (familyId: string) => void;
  onBadgeClick: (callback: () => void) => void;
  setAggregateRatingSchema: (schema: 'json-ld' | 'microdata') => void;
  reset: () => void;
}

const noop = () => {};

const TestFreaksContext = createContext<TestFreaksContextValue>({
  load: noop,
  setProductId: noop,
  setFamilyId: noop,
  onBadgeClick: noop,
  setAggregateRatingSchema: noop,
  reset: noop,
});

declare global {
  interface Window {
    testFreaks?: TestFreaksApi;
  }
}

interface Props {
  clientId: string;
  isDemo: boolean;
}

export const useTestFreaks = () => useContext(TestFreaksContext);

const getApi: () => TestFreaksApi = () => {
  if (isServer()) {
    return [];
  }

  if (!window.testFreaks) {
    window.testFreaks = [];
  }

  return window.testFreaks;
};

const filterUnique = <T,>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

export const TestFreaksProvider: React.FC<React.PropsWithChildren<Props>> = ({
  clientId,
  isDemo,
  children,
}) => {
  const [loadingQueue, setLoadingQueue] = useState<TestFreaksElement[]>([]);

  useEffect(() => {
    if (loadingQueue.length > 0) {
      const timeoutId = setTimeout(() => {
        setLoadingQueue([]);
        getApi().push(['load', loadingQueue.filter(filterUnique)]);
      }, 400);

      return () => clearTimeout(timeoutId);
    }
  }, [loadingQueue]);

  const contextValue: TestFreaksContextValue = useMemo(
    () => ({
      load: (elements: TestFreaksElement[]) => {
        setLoadingQueue((q) => [...q, ...elements]);
      },
      setProductId: (productId: string) => {
        const demoableProductId = isDemo ? 'demo' : productId;
        getApi().push(['setProductId', demoableProductId]);
      },
      setFamilyId: (familyId: string) => {
        getApi().push(['setFamilyId', familyId]);
      },
      setAggregateRatingSchema: (schema: AggregatedRatingSchema) => {
        getApi().push(['setAggregateRatingSchema', schema]);
      },
      onBadgeClick: (callback: () => void) => {
        getApi().push(['onBadgeClick', callback]);
      },
      reset: () => {
        getApi().push(['reset']);
      },
    }),
    [isDemo]
  );

  return (
    <TestFreaksContext.Provider value={contextValue}>
      <Script
        src={`https://js.testfreaks.com/onpage/${clientId}/head.js`}
        strategy={ScriptStrategy.idle}
      ></Script>
      {children}
    </TestFreaksContext.Provider>
  );
};
