import { CartRow } from '@apis/expedition';

export const EXPEDITION_CART_QUANTITY_CHANGED = 'EXPEDITION_CART/QUANTITY_CHANGED';

export interface ExpeditionCartQuantityChanged {
  type: typeof EXPEDITION_CART_QUANTITY_CHANGED;
  payload: {
    previousRow: CartRow;
    quantity: number;
  };
}

export const EXPEDITION_CART_ROW_REMOVED = 'EXPEDITION_CART/ROW_REMOVED';

export interface ExpeditionCartRowRemoved {
  type: typeof EXPEDITION_CART_ROW_REMOVED;
  payload: {
    previousRow: CartRow;
  };
}

export type AnalyticsEvents = ExpeditionCartQuantityChanged | ExpeditionCartRowRemoved;
