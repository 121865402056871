import { ORDER_CONFIRMED } from '../../../../../../checkout/analytics';
import { PAGE_VIEWED } from '../../../../../analytics';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const pageViewedTracker = createSimpleTracker({
  [ORDER_CONFIRMED]: async ({ event }) => {
    const originalLocationFromStorage = sessionStorage.getItem('originalLocation');
    const { url } = event.payload;

    if (originalLocationFromStorage === null) {
      sessionStorage.setItem('originalLocation', url);
      return { event: 'location', originalLocation: url };
    } else {
      return { event: 'location', originalLocation: originalLocationFromStorage };
    }
  },
  [PAGE_VIEWED]: ({ event: { payload } }) => {
    const { url, isMemberLoggedIn, isRXLoggedIn } = payload;
    const originalLocationFromStorage = sessionStorage.getItem('originalLocation');

    if (originalLocationFromStorage === null) {
      const locationEvent = { event: 'location', originalLocation: url };
      sessionStorage.setItem('originalLocation', url);

      return [
        locationEvent,
        {
          event: 'kronanPageReady',
          RX_logged_in: isRXLoggedIn ? 'yes' : 'no',
          kundklubb_logged_in: isMemberLoggedIn ? 'yes' : 'no',
        },
      ];
    }
    return {
      event: 'kronanPageReady',
      RX_logged_in: isRXLoggedIn ? 'yes' : 'no',
      kundklubb_logged_in: isMemberLoggedIn ? 'yes' : 'no',
    };
  },
});
