import { BREADCRUMB_CLICKED } from '@packages/ui';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const breadcrumbTracker = createSimpleTracker({
  [BREADCRUMB_CLICKED]: ({ event }) => {
    const index = event.payload.all.findIndex((b) => b.url === event.payload.breadcrumb.url);

    if (index >= 0) {
      return {
        event: 'breadcrumb',
        event_info: {
          event_name: 'breadcrumb_click',
          user_interaction: 'link_click',
          navigation_selection: convertTextToSlug(
            event.payload.all
              .filter((_, i) => i <= index)
              .map((b) => b.name)
              .join('/')
          ),
          link_position: `${index + 1}_${event.payload.all.length}`,
        },
      };
    }
  },
});
