import { createContext } from 'react';
import {
  Cart,
  ConfirmCartResponse,
  MedicalCounselingPreferences,
  PrescribedDetailsRequested,
} from './types';

type UnsubscribeFn = () => void;

export interface CartContextType {
  cart: Cart | null;
  isUpdating: boolean;
  hasErrors: boolean;
  refresh: () => Promise<void>;
  touch: () => Promise<void>;
  updateWantsToBecomeMember: (wantsToBecomeMember: boolean) => Promise<void>;
  destroyRx: () => Promise<void>;
  addBonusCheck: (bonusCheckId: string) => Promise<void>;
  removeBonusCheck: (bonusCheckId: string) => Promise<void>;
  confirmByPaymentId: (paymentId: string) => Promise<ConfirmCartResponse>;
  initiateCheckout: () => Promise<void>;
  addPrescriptionBag: (prescriptionBagId: string) => Promise<void>;
  removePrescriptionBag: () => Promise<void>;
  updatePrescribedDetailsInformationPreferences: (
    preferences: PrescribedDetailsRequested
  ) => Promise<void>;
  updateMedicalCounselingPreferences: (preferences: MedicalCounselingPreferences) => Promise<void>;
  addItemToCart: (itemId: string, quantity: number) => Promise<void>;
  addItemsToCart: (items: { itemId: string; quantity: number }[]) => Promise<void>;
  updateItemInCart: (itemId: string, quantity: number) => Promise<void>;
  deleteItemFromCart: (itemId: string) => Promise<void>;
  addVoucherToCart: (voucherCode: string) => Promise<void>;
  deleteVoucherFromCart: (voucherCode: string) => Promise<void>;
  onCartChangeStart: (callback: () => void) => UnsubscribeFn;
  onCartChangeEnd: (callback: () => void) => UnsubscribeFn;
}

const noCartProviderFoundFunction = () => {
  throw new Error('No cart provider found');
};

export const CartContext = createContext<CartContextType>({
  cart: null,
  isUpdating: false,
  hasErrors: false,
  refresh: noCartProviderFoundFunction,
  touch: noCartProviderFoundFunction,
  updateWantsToBecomeMember: noCartProviderFoundFunction,
  destroyRx: noCartProviderFoundFunction,
  addBonusCheck: noCartProviderFoundFunction,
  removeBonusCheck: noCartProviderFoundFunction,
  confirmByPaymentId: noCartProviderFoundFunction,
  initiateCheckout: noCartProviderFoundFunction,
  addPrescriptionBag: noCartProviderFoundFunction,
  removePrescriptionBag: noCartProviderFoundFunction,
  updatePrescribedDetailsInformationPreferences: noCartProviderFoundFunction,
  updateMedicalCounselingPreferences: noCartProviderFoundFunction,
  addItemToCart: noCartProviderFoundFunction,
  addItemsToCart: noCartProviderFoundFunction,
  updateItemInCart: noCartProviderFoundFunction,
  deleteItemFromCart: noCartProviderFoundFunction,
  addVoucherToCart: noCartProviderFoundFunction,
  deleteVoucherFromCart: noCartProviderFoundFunction,
  onCartChangeStart: noCartProviderFoundFunction,
  onCartChangeEnd: noCartProviderFoundFunction,
});
