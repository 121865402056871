import { BannerBoxSource } from '../types';

export const HERO_CLICKED = 'HERO/CLICKED';

export interface HeroClicked {
  type: typeof HERO_CLICKED;
  payload: {
    title: string;
    position: number;
    isCarousel: boolean;
    type: BannerBoxSource;
  };
}
export const HERO_VIEWED = 'HERO/VIEWED';

export interface HeroViewed {
  type: typeof HERO_VIEWED;
  payload: {
    title: string;
    position: number;
    isCarousel: boolean;
    type: BannerBoxSource;
  };
}

export type AnalyticsEvents = HeroClicked | HeroViewed;
