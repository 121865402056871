import { MEDIA_QUERIES } from '@oriola-corporation/ui-components';

type DeviceSizeKey = keyof typeof MEDIA_QUERIES;
export type DeviceSize = 0 | 1 | 2 | 3;
export const DEVICE_SIZES: Record<DeviceSizeKey, DeviceSize> = {
  XSMALL: 0,
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};
