import { AnalyticsEvents as CartAnalyticsEvents } from './cart/analytics';
import { AnalyticsEvents as PrescriptionsAnalyticsEvents } from './prescriptions/analytics';

export const PRESCRIPTION_CARD_VIEWED = 'PRESCRIPTION_CARD/VIEWED';
export interface PrescriptionCardViewed {
  type: typeof PRESCRIPTION_CARD_VIEWED;
  payload: {
    position: number;
  };
}

export const PRESCRIPTION_CARD_CLICKED = 'PRESCRIPTION_CARD/CLICKED';
export interface PrescriptionCardClicked {
  type: typeof PRESCRIPTION_CARD_CLICKED;
  payload: {
    position: number;
  };
}

export const RX_MENU_ITEM_CLICKED = 'RX/MENU_ITEM_CLICKED';
export interface RxMenuItemClicked {
  type: typeof RX_MENU_ITEM_CLICKED;
  payload: {
    id: string;
  };
}

export const RX_MENU_SUBMENU_ITEM_CLICKED = 'RX/MENU_SUBMENU_ITEM_CLICKED';
export interface RxMenuSubmenuItemClicked {
  type: typeof RX_MENU_SUBMENU_ITEM_CLICKED;
  payload: {
    id: string;
  };
}

export const RX_MOBILE_MENU_OPENED = 'RX/MOBILE_MENU_OPENED';
export interface RxMobileMenuOpened {
  type: typeof RX_MOBILE_MENU_OPENED;
  payload: {
    name: string;
  };
}

export const RX_MOBILE_MENU_CLOSED = 'RX/MOBILE_MENU_CLOSED';
export interface RxMobileMenuClosed {
  type: typeof RX_MOBILE_MENU_CLOSED;
}

export const TEENAGER_COPY_VIEWED = 'RX/TEENAGER_COPY_VIEWED';
export interface TeenagerCopyViewed {
  type: typeof TEENAGER_COPY_VIEWED;
  payload: {
    title: string;
  };
}

export type AnalyticsEvents =
  | PrescriptionCardViewed
  | PrescriptionCardClicked
  | PrescriptionsAnalyticsEvents
  | CartAnalyticsEvents
  | RxMenuItemClicked
  | RxMenuSubmenuItemClicked
  | RxMobileMenuOpened
  | RxMobileMenuClosed
  | TeenagerCopyViewed;
