import { CalendarDayStatus } from './calendarBlock';

export const CALENDAR_CARD_CLICKED = 'CALENDAR_CARD_CLICKED';

type Item = {
  heading: string;
  position: number;
  status: CalendarDayStatus;
};

export interface CalendarCardClicked {
  type: typeof CALENDAR_CARD_CLICKED;
  payload: Item;
}

export const CALENDAR_CARD_VIEWED = 'CALENDAR_VIEWED';

export interface CalendarViewed {
  type: typeof CALENDAR_CARD_VIEWED;
  payload: Item;
}

export type AnalyticsEvents = CalendarCardClicked | CalendarViewed;
