export const uniqueArray = <T>(arr: T[], attributeGetter: (item: T) => string | number) => {
  const map: {
    [key: string]: boolean;
    [key: number]: boolean;
  } = {};
  const result: T[] = [];

  arr.forEach((item) => {
    const key = attributeGetter(item);

    if (!map[key]) {
      map[key] = true;
      result.push(item);
    }
  });

  return result;
};
