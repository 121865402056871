export const PHARMACIES_LINK_CLICKED = 'PHARMACIES/LINK_CLICKED';

export interface PharmaicesLinkClicked {
  type: typeof PHARMACIES_LINK_CLICKED;
  payload: {
    linkText: string;
    userInteraction: 'button_click' | 'link_click';
  };
}

export const PHARMACIES_PHARAMCY_CARD_LINK_CLICKED = 'PHARMACIES/PHARAMCY_CARD_LINK_CLICKED';

export interface PharmacyCardLinkClicked {
  type: typeof PHARMACIES_PHARAMCY_CARD_LINK_CLICKED;
  payload: {
    linkText: string;
    userInteraction: 'button_click' | 'link_click' | 'map_click';
  };
}

export const FIND_PHARMACY_LIST_MAP_TOGGLED = 'FIND_PHARMACY/LIST_MAP_TOGGLED';

export interface FindPharmacyListMapToggled {
  type: typeof FIND_PHARMACY_LIST_MAP_TOGGLED;
  payload: {
    linkText: 'visa lista' | 'visa karta';
    userInteraction: 'button_click';
  };
}

export const FIND_PHARMACY_MAP_CLICKED = 'FIND_PHARMACY/MAP_CLICKED';

export interface FindPharmacyMapClicked {
  type: typeof FIND_PHARMACY_MAP_CLICKED;
  payload: {
    linkText: string;
    userInteraction: 'map_click';
  };
}

export const FIND_PHARMACY_FILTER_SELECTED = 'FIND_PHARMACY/FILTER_SELECTED';

export interface FindPharmacyFilterSelected {
  type: typeof FIND_PHARMACY_FILTER_SELECTED;
  payload: {
    userInteraction: 'filter_selected';
    filterType: string;
    filterSelection: string;
    filterNumberOfProducts: number;
  };
}

export const FIND_PHARMACY_FILTER_CLEARED = 'FIND_PHARMACY/FILTER_CLEARED';

export interface FindPharmacyFilterCleared {
  type: typeof FIND_PHARMACY_FILTER_CLEARED;
}

export const REGION_LINK_CLICKED = 'FIND_PHARMACY/REGION_LINK_CLICKED';

export interface RegionLinkClicked {
  type: typeof REGION_LINK_CLICKED;
  payload: {
    linkText: string;
  };
}

export type AnalyticsEvents =
  | PharmaicesLinkClicked
  | PharmacyCardLinkClicked
  | FindPharmacyListMapToggled
  | FindPharmacyMapClicked
  | FindPharmacyFilterSelected
  | FindPharmacyFilterCleared
  | RegionLinkClicked;
