import axios from 'axios';
import { DeliveryParametersCollection, RequestRow } from './types';

export const createApiClient = (baseUrl = '/api/delivery-parameters') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getAxiosInstance: () => client,
    getParameters: async (rows: RequestRow[]): Promise<DeliveryParametersCollection> => {
      const response = await client.post<DeliveryParametersCollection>('/parameters', {
        rows,
      });
      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
