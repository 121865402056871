import { Product } from '@oriola-corporation/product-types';
import { createBatchLoaderContext } from '@oriola-corporation/ui-components';
import { useApi } from '@packages/api';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';

const { useItem: useBatchedItem, Provider } = createBatchLoaderContext<Product>(
  (product) => product.productNumber,
  ReactDOM.unstable_batchedUpdates
);

export const useItem = (itemId: string) => {
  const { isLoading, hasErrors, data } = useBatchedItem(itemId);

  return {
    isLoading,
    hasErrors,
    item: data,
  };
};

export const useItems = (itemIds: string[]) => {
  const {
    itemWeb: { getItems },
  } = useApi();

  const key = itemIds.join(',');

  return useQuery({
    queryKey: ['items', key],
    queryFn: () => getItems(itemIds),
    enabled: Array.isArray(itemIds) && itemIds.length > 0,
  });
};

export const ItemDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    itemWeb: { getItems },
  } = useApi();

  return (
    <Provider loadItems={getItems} debounce={100}>
      {children}
    </Provider>
  );
};
