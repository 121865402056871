import { ProductCardProduct } from '../product/productCard/types';

export const PRODUCT_BUNDLE_BOUGHT = 'PRODUCT_BUNDLE/BOUGHT';

export interface ProductBundleBought {
  type: typeof PRODUCT_BUNDLE_BOUGHT;
  payload: {
    products: Array<{
      product: ProductCardProduct;
      previousQuantity: number;
      quantity: number;
    }>;
  };
}

export const PRODUCT_BUNDLE_VIEWED = 'PRODUCT_BUNDLE/VIEWED';

export interface ProductBundleViewed {
  type: typeof PRODUCT_BUNDLE_VIEWED;
  payload: {
    products: ProductCardProduct[];
  };
}

export const PRODUCT_BUNDLE_CLICKED = 'PRODUCT_BUNDLE/CLICKED';
export interface ProductBundleClicked {
  type: typeof PRODUCT_BUNDLE_CLICKED;
  payload: {
    product: ProductCardProduct;
    position: number;
  };
}

export type AnalyticsEvents = ProductBundleBought | ProductBundleViewed | ProductBundleClicked;
