import { AnalyticsEvents as BuyableOptionsAnalyticsEvents } from './buyableOptions/analytics';

export const EXPIRED_PRESCRIPTIONS_TOGGLED = 'PRESCRIPTIONS_LIST/EXPIRED_PRESCRIPTIONS_TOGGLED';
export interface ExpiredPrescriptionsToggled {
  type: typeof EXPIRED_PRESCRIPTIONS_TOGGLED;
  payload: {
    isToggled: boolean;
    label: string;
  };
}

export const PRESCRIPTIONS_LIST_DROPDOWN_TOGGLED =
  'PRESCRIPTIONS_LIST/PRESCRIPTIONS_LIST_DROPDOWN_TOGGLED';

export interface PrescriptionsListDropdownToggled {
  type: typeof PRESCRIPTIONS_LIST_DROPDOWN_TOGGLED;
  payload: {
    isOpen: boolean;
    current: string;
  };
}

export const PRESCRIPTIONS_LIST_SORTING_OPTION_CHANGED =
  'PRESCRIPTIONS_LIST/PRESCRIPTIONS_LIST_SORTING_OPTION_CHANGED';

export interface PrescriptionsListSortingOptionChanged {
  type: typeof PRESCRIPTIONS_LIST_SORTING_OPTION_CHANGED;
  payload: {
    to: string;
    from: string;
  };
}

export type AnalyticsEvents =
  | ExpiredPrescriptionsToggled
  | PrescriptionsListDropdownToggled
  | PrescriptionsListSortingOptionChanged
  | BuyableOptionsAnalyticsEvents;
