import { addToStorage } from '@packages/analytics';
import { isDefined } from '@packages/utils';
import { EXTERNAL_ITEMS_ADDED_TO_CART } from '../../../../../../checkout/analytics';
import {
  CartItemChanged,
  CART_ITEM_CHANGED,
  CART_VIEWED,
} from '../../../../../../components/cart/analytics';
import {
  EXPEDITION_CART_QUANTITY_CHANGED,
  EXPEDITION_CART_ROW_REMOVED,
} from '../../../../../../components/expedition/cart/analytics';
import {
  SingleOptionAddedToCart,
  SINGLE_OPTION_ADDED_TO_CART,
  SubstitutionsOptionsOptionAddedToCart,
  SUBSTITUTIONS_OPTIONS_OPTION_ADDED_TO_CART,
} from '../../../../../../components/expedition/prescriptions/buyableOptions/analytics';
import {
  ProductPageCartChange,
  PRODUCT_PAGE_CART_CHANGE,
} from '../../../../../../components/pages/productPage/analytics';
import {
  FlyoutProductListItemCartChanged,
  FLYOUT_PRODUCT_LIST_ITEM_CART_CHANGED,
} from '../../../../../../components/product/flyoutProductList/analytics';
import {
  ProductCardCartChanged,
  PRODUCT_CARD_CART_CHANGED,
} from '../../../../../../components/product/productCard/analytics';
import {
  ProductBundleBought,
  PRODUCT_BUNDLE_BOUGHT,
} from '../../../../../../components/productBundle/analytics';
import { Metadata } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import {
  formatFakeRxProduct,
  formatProduct,
  GAFormattedProduct,
  toTrackableProduct,
} from '../utils/formatProduct';
import { formatProductOption } from '../utils/formatProductOption';
import { parseListNameFromMetadata } from '../utils/parseListNameFromMetadata';

const createAddToCartEvent = <T extends GAFormattedProduct>({
  list,
  items,
}: {
  list: string;
  items: Array<{ quantity: number; product: T }>;
}) => {
  addToStorage({ key: list, item: items.map(({ product }) => product.id) });
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'SEK',
      add: {
        actionField: {
          list,
        },
        products: items.map(({ quantity, product }) => ({
          ...product,
          quantity,
        })),
      },
    },
  };
};

const createRemoveFromCartEvent = <T extends GAFormattedProduct>({
  list,
  items,
}: {
  list: string;
  items: Array<{ quantity: number; product: T }>;
}) => {
  return {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'SEK',
      remove: {
        actionField: {
          list,
        },
        products: items.map(({ quantity, product }) => ({
          ...product,
          quantity,
        })),
      },
    },
  };
};

const trackCartChanges = ({
  event,
  metadata,
}: {
  event:
    | FlyoutProductListItemCartChanged
    | ProductCardCartChanged
    | ProductPageCartChange
    | CartItemChanged;
  metadata: Metadata;
}) => {
  const listName =
    'list' in event.payload && event.payload.list !== null ? event.payload.list : undefined;
  const list = (metadata.pageType as string) || listName || 'N/A';

  const { product: rawProduct, quantity, previousQuantity } = event.payload;

  const product = toTrackableProduct(rawProduct);

  if (quantity !== previousQuantity) {
    const quantityIncreased = quantity > previousQuantity;
    let quantityToReport = quantity;
    if (quantityIncreased) {
      quantityToReport -= previousQuantity;
    } else {
      quantityToReport = previousQuantity - quantity;
    }

    if (quantityIncreased) {
      return createAddToCartEvent({
        items: [{ product: formatProduct(product), quantity: quantityToReport }],
        list,
      });
    } else {
      return createRemoveFromCartEvent({
        list,
        items: [{ product: formatProduct(product), quantity: quantityToReport }],
      });
    }
  }
};

const trackProductBundleBought = ({ event }: { event: ProductBundleBought }) => {
  const { products } = event.payload;

  if (products.length > 0) {
    return createAddToCartEvent({
      list: 'often-bought-together',
      items: products.map(({ product, quantity, previousQuantity }) => ({
        product: formatProduct(toTrackableProduct(product)),
        quantity: (quantity ?? 0) - (previousQuantity ?? 0),
      })),
    });
  }
};
const trackPrescriptionProductAddedToCart = ({
  event,
  metadata,
}: {
  event: SubstitutionsOptionsOptionAddedToCart | SingleOptionAddedToCart;
  metadata: Metadata;
}) => {
  const { productOption, quantity, hasSubstitution } = event.payload;

  return createAddToCartEvent({
    items: [
      {
        product: formatProductOption(
          productOption.price,
          quantity,
          event.type === SINGLE_OPTION_ADDED_TO_CART ? 'prescribed' : event.payload.variant,
          productOption.buyableStatus.reasonTitle
        ),
        quantity,
      },
    ],
    list: `${parseListNameFromMetadata(metadata)}${hasSubstitution ? '_prescription_list' : ''}`,
  });
};

const convertToCartItem = (formattedProduct: GAFormattedProduct, quantity: number) => {
  const { id, name, brand, category, ...rest } = formattedProduct;

  const transformedData = {
    ...rest,
    item_id: id,
    item_name: name,
    item_brand: brand,
    item_category: category,
    quantity,
  };

  return transformedData;
};

export const cartTracker = createSimpleTracker({
  [EXTERNAL_ITEMS_ADDED_TO_CART]: async ({ event }, { itemWeb }) => {
    const { itemIds } = event.payload;

    const products = await itemWeb.getItems(itemIds);

    return itemIds
      .map((itemId) => {
        const productData = products.find((p) => p.productNumber === itemId);

        if (!productData) {
          return null;
        }

        return productData;
      })
      .filter(isDefined)
      .map((product) => {
        return createAddToCartEvent({
          items: [{ product: formatProduct(product), quantity: 1 }],
          list: 'inject_to_cart',
        });
      });
  },
  [CART_ITEM_CHANGED]: trackCartChanges,
  [PRODUCT_PAGE_CART_CHANGE]: trackCartChanges,
  [PRODUCT_BUNDLE_BOUGHT]: trackProductBundleBought,
  [PRODUCT_CARD_CART_CHANGED]: trackCartChanges,
  [FLYOUT_PRODUCT_LIST_ITEM_CART_CHANGED]: trackCartChanges,
  [SUBSTITUTIONS_OPTIONS_OPTION_ADDED_TO_CART]: trackPrescriptionProductAddedToCart,
  [SINGLE_OPTION_ADDED_TO_CART]: trackPrescriptionProductAddedToCart,
  [EXPEDITION_CART_QUANTITY_CHANGED]: ({ event, metadata }) => {
    const { previousRow, quantity } = event.payload;

    if (quantity > previousRow.selectedQuantity) {
      return createAddToCartEvent({
        items: [
          {
            product: formatProductOption(
              previousRow.unitPrice,
              previousRow.selectedQuantity,
              previousRow.substitutionId ? 'substitution' : 'prescribed'
            ),
            quantity: quantity - previousRow.selectedQuantity,
          },
        ],
        list: metadata.pageType as string,
      });
    } else {
      return createRemoveFromCartEvent({
        items: [
          {
            product: formatProductOption(
              previousRow.unitPrice,
              previousRow.selectedQuantity,
              previousRow.substitutionId ? 'substitution' : 'prescribed'
            ),
            quantity: previousRow.selectedQuantity - quantity,
          },
        ],
        list: metadata.pageType as string,
      });
    }
  },
  [EXPEDITION_CART_ROW_REMOVED]: ({ event, metadata }) => {
    const { previousRow } = event.payload;

    return createRemoveFromCartEvent({
      items: [
        {
          product: formatProductOption(
            previousRow.unitPrice,
            previousRow.selectedQuantity,
            previousRow.substitutionId ? 'substitution' : 'prescribed'
          ),
          quantity: previousRow.selectedQuantity,
        },
      ],
      list: metadata.pageType as string,
    });
  },
  [CART_VIEWED]: async ({ event }, { itemWeb }) => {
    const { cart } = event.payload;

    const itemIds = cart.rows.map((cartItem) => cartItem.itemId);

    const productData = await itemWeb.getItems(itemIds);

    const items = cart.rows
      .map((cartItem) => {
        const itemData = productData.find((product) => product.productNumber === cartItem.itemId);

        if (itemData) {
          const trackingData = formatProduct(itemData);

          return convertToCartItem(trackingData, cartItem.quantity);
        }

        return null;
      })
      .filter(isDefined);

    const prescriptionBag = cart.newPrescriptionBag;

    if (prescriptionBag?.status === 'INCLUDED' && prescriptionBag.content.totalRows > 0) {
      const normalItem = formatFakeRxProduct(
        (prescriptionBag.content.totalPrice - prescriptionBag.content.vatAmount) /
          (100 * prescriptionBag.content.quantity)
      );

      items.push(convertToCartItem(normalItem, prescriptionBag.content.quantity));
    }

    if (items.length > 0) {
      return {
        event: 'view_cart',
        ecommerce: {
          items,
        },
      };
    }
  },
});
