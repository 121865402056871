import { ProductOption } from '@apis/expedition';

export const SUBSTITUTIONS_OPTIONS_OPTION_VIEWED = 'SUBSTITUTIONS_OPTIONS/OPTION_VIEWED';

export interface SubstitutionsOptionsOptionViewed {
  type: typeof SUBSTITUTIONS_OPTIONS_OPTION_VIEWED;
  payload: {
    quantity: number;
    productOption: ProductOption;
    variant: 'prescribed' | 'substitution';
    position: number;
    hasSubstitution: boolean;
  };
}

export const SUBSTITUTIONS_OPTIONS_OPTION_ADDED_TO_CART =
  'SUBSTITUTIONS_OPTIONS/OPTION_ADDED_TO_CART';

export interface SubstitutionsOptionsOptionAddedToCart {
  type: typeof SUBSTITUTIONS_OPTIONS_OPTION_ADDED_TO_CART;
  payload: {
    quantity: number;
    productOption: ProductOption;
    variant: 'prescribed' | 'substitution';
    position: number;
    hasSubstitution: boolean;
  };
}

export const SHOW_MORE_SUBSTITUTIONS_MODAL_TOGGLED = 'SHOW_MORE_SUBSTITUTIONS/MODAL_TOGGLED';

export interface ShowMoreSubstitutionsModalToggled {
  type: typeof SHOW_MORE_SUBSTITUTIONS_MODAL_TOGGLED;
  payload: {
    isOpen: boolean;
  };
}

export const PRESCRIBED_OPTION_PRODUCT_DETAIL = 'PRESCRIBED_OPTION/PRODUCT_DETAIL';

export interface PrescribedOptionProductDetail {
  type: typeof PRESCRIBED_OPTION_PRODUCT_DETAIL;
  payload: {
    productOption: ProductOption;
    hasSubstitution: boolean;
  };
}

export const SINGLE_OPTION_ADDED_TO_CART = 'SINGLE_OPTION/ADDED_TO_CART';

export interface SingleOptionAddedToCart {
  type: typeof SINGLE_OPTION_ADDED_TO_CART;
  payload: {
    quantity: number;
    productOption: ProductOption;
    hasSubstitution: boolean;
  };
}

export const EXPANDABLE_CONTENT_ON_TOGGLE = 'SUBSTITUTIONS_PROMOTER/EXPANDABLE_CONTENT_ON_TOGGLE';

export interface ExpandableContentOnToggle {
  type: typeof EXPANDABLE_CONTENT_ON_TOGGLE;
  payload: {
    isOpen: boolean;
    label: string;
  };
}

export type AnalyticsEvents =
  | SubstitutionsOptionsOptionViewed
  | SubstitutionsOptionsOptionAddedToCart
  | ShowMoreSubstitutionsModalToggled
  | PrescribedOptionProductDetail
  | SingleOptionAddedToCart
  | ExpandableContentOnToggle;
