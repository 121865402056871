import { BannerBox, BannerBoxSource } from '../types';

export const BANNER_BOX_VIEWED = 'BANNER_BOX/VIEWED';
export interface BannerBoxViewed {
  type: typeof BANNER_BOX_VIEWED;
  payload: {
    banner: BannerBox;
    position: number;
    size: 'small' | 'medium' | 'large';
    type: BannerBoxSource;
  };
}

export const BANNER_BOX_CLICKED = 'BANNER_BOX/CLICKED';

export interface BannerBoxClicked {
  type: typeof BANNER_BOX_CLICKED;
  payload: {
    banner: BannerBox;
    position: number;
    size: 'small' | 'medium' | 'large';
    type: BannerBoxSource;
  };
}

export type AnalyticsEvents = BannerBoxViewed | BannerBoxClicked;
