import type { QueryKey } from './types';

type QueryKeyBuilder = (...keys: QueryKey[]) => QueryKey[];

export const createQueryKeyBuilder =
  (builder: QueryKeyBuilder | string, suffix: string): QueryKeyBuilder =>
  (...keys: QueryKey[][] | QueryKey[]) => {
    const typedFlatKeys = keys.flat() as QueryKey[];

    if (typeof builder === 'string') {
      return [builder, ...typedFlatKeys];
    }

    return builder(suffix, ...typedFlatKeys);
  };

export const emptyQueryKeyBuilder: QueryKeyBuilder = (...keys) => keys;
