import { ProductCardProduct } from './types';

export const PRODUCT_CARD_VIEWED = 'PRODUCT_CARD/VIEWED';
export interface ProductCardViewed {
  type: typeof PRODUCT_CARD_VIEWED;
  payload: {
    product: ProductCardProduct;
    position: number;
    list?: string | null;
  };
}

export const PRODUCT_CARD_CLICKED = 'PRODUCT_CARD/CLICKED';

export interface ProductCardClicked {
  type: typeof PRODUCT_CARD_CLICKED;
  payload: {
    product: ProductCardProduct;
    position: number;
    list?: string | null;
  };
}

export const PRODUCT_CARD_CART_CHANGED = 'PRODUCT_CARD/CART_CHANGED';

export interface ProductCardCartChanged {
  type: typeof PRODUCT_CARD_CART_CHANGED;
  payload: {
    product: ProductCardProduct;
    quantity: number;
    previousQuantity: number;
    list?: string | null;
  };
}

export type AnalyticsEvents = ProductCardViewed | ProductCardClicked | ProductCardCartChanged;
