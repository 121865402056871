import axios from 'axios';
import {
  AccessTokenResponse,
  AuthMethod,
  BankIdCollectDTO,
  BankIdInitDTO,
  LoginType,
  MockUser,
} from './types';

export const createApiClient = ({ domain, basePath }: { domain: string; basePath: string }) => {
  const client = axios.create({
    baseURL: `${domain}${basePath}`,
    withCredentials: true,
  });

  return {
    getAxiosInstance: () => client,
    getLoginUrl: (authMethod: AuthMethod, redirectUrl: string) =>
      `${domain}${basePath}/auth/login?regId=${authMethod}&redirectUrl=${encodeURIComponent(
        redirectUrl
      )}`,
    getFakeLoginUrl: (redirectUrl: string, user: MockUser) =>
      `${domain}${basePath}/generate?redirectUrl=${encodeURIComponent(redirectUrl)}&pnr=${
        user.personalNumber
      }&fname=${user.firstName}&lname=${user.lastName}`,
    getAccessToken: async () => {
      const response = await client.get<AccessTokenResponse>('/auth/refresh');
      return response.data;
    },
    logout: async () => {
      await client.get('/auth/logout');
    },

    initBankId: async (loginType: LoginType) =>
      (await client.post<BankIdInitDTO>(`/v2/bankid/login/${loginType}`)).data,

    collectBankId: async (sessionId: string) => {
      try {
        const response = await client.post<BankIdCollectDTO>(`/v2/bankid/session/${sessionId}`);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    logoutBankId: async (sessionId: string) =>
      (await client.post(`/v2/bankid/logout/${sessionId}`)).data,
  };
};
