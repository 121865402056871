exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bevaka-vara-tsx": () => import("./../../../src/pages/bevaka-vara.tsx" /* webpackChunkName: "component---src-pages-bevaka-vara-tsx" */),
  "component---src-pages-hitta-apotek-tsx": () => import("./../../../src/pages/hitta-apotek.tsx" /* webpackChunkName: "component---src-pages-hitta-apotek-tsx" */),
  "component---src-pages-hitta-produkter-tsx": () => import("./../../../src/pages/hitta-produkter.tsx" /* webpackChunkName: "component---src-pages-hitta-produkter-tsx" */),
  "component---src-pages-kassa-external-tsx": () => import("./../../../src/pages/kassa/external.tsx" /* webpackChunkName: "component---src-pages-kassa-external-tsx" */),
  "component---src-pages-kassa-index-tsx": () => import("./../../../src/pages/kassa/index.tsx" /* webpackChunkName: "component---src-pages-kassa-index-tsx" */),
  "component---src-pages-kassa-tack-tsx": () => import("./../../../src/pages/kassa/tack.tsx" /* webpackChunkName: "component---src-pages-kassa-tack-tsx" */),
  "component---src-pages-kundklubb-login-tsx": () => import("./../../../src/pages/kundklubb-login.tsx" /* webpackChunkName: "component---src-pages-kundklubb-login-tsx" */),
  "component---src-pages-verifierad-tsx": () => import("./../../../src/pages/verifierad.tsx" /* webpackChunkName: "component---src-pages-verifierad-tsx" */),
  "component---src-templates-doktor-se-index-tsx": () => import("./../../../src/templates/doktorSe/index.tsx" /* webpackChunkName: "component---src-templates-doktor-se-index-tsx" */),
  "component---src-templates-external-index-tsx": () => import("./../../../src/templates/external/index.tsx" /* webpackChunkName: "component---src-templates-external-index-tsx" */),
  "component---src-templates-gatsby-brand-start-page-tsx": () => import("./../../../src/templates/gatsbyBrandStartPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-brand-start-page-tsx" */),
  "component---src-templates-gatsby-campaign-page-tsx": () => import("./../../../src/templates/gatsbyCampaignPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-campaign-page-tsx" */),
  "component---src-templates-gatsby-campaign-start-page-tsx": () => import("./../../../src/templates/gatsbyCampaignStartPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-campaign-start-page-tsx" */),
  "component---src-templates-gatsby-category-page-tsx": () => import("./../../../src/templates/gatsbyCategoryPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-category-page-tsx" */),
  "component---src-templates-gatsby-content-page-tsx": () => import("./../../../src/templates/gatsbyContentPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-content-page-tsx" */),
  "component---src-templates-gatsby-find-pharmacy-by-region-tsx": () => import("./../../../src/templates/gatsbyFindPharmacyByRegion.tsx" /* webpackChunkName: "component---src-templates-gatsby-find-pharmacy-by-region-tsx" */),
  "component---src-templates-gatsby-product-page-tsx": () => import("./../../../src/templates/gatsbyProductPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-product-page-tsx" */),
  "component---src-templates-gatsby-root-page-tsx": () => import("./../../../src/templates/gatsbyRootPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-root-page-tsx" */),
  "component---src-templates-gatsby-rx-page-tsx": () => import("./../../../src/templates/gatsbyRxPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-rx-page-tsx" */),
  "component---src-templates-gatsby-start-page-tsx": () => import("./../../../src/templates/gatsbyStartPage.tsx" /* webpackChunkName: "component---src-templates-gatsby-start-page-tsx" */),
  "component---src-templates-member-index-tsx": () => import("./../../../src/templates/member/index.tsx" /* webpackChunkName: "component---src-templates-member-index-tsx" */),
  "component---src-templates-order-page-index-tsx": () => import("./../../../src/templates/orderPage/index.tsx" /* webpackChunkName: "component---src-templates-order-page-index-tsx" */),
  "component---src-templates-order-page-return-page-tsx": () => import("./../../../src/templates/orderPage/ReturnPage.tsx" /* webpackChunkName: "component---src-templates-order-page-return-page-tsx" */),
  "component---src-templates-pharmacy-page-index-tsx": () => import("./../../../src/templates/pharmacyPage/index.tsx" /* webpackChunkName: "component---src-templates-pharmacy-page-index-tsx" */),
  "component---src-templates-prescription-index-tsx": () => import("./../../../src/templates/prescription/index.tsx" /* webpackChunkName: "component---src-templates-prescription-index-tsx" */),
  "component---src-templates-preview-page-index-tsx": () => import("./../../../src/templates/previewPage/index.tsx" /* webpackChunkName: "component---src-templates-preview-page-index-tsx" */)
}

