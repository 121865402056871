type Payload = {
  id: string;
};

export const CONTENT_PAGE_MOBILE_MENU_OPENED = 'CONTENT_PAGE_MOBILE_MENU/OPENED';

export interface ContentPageMobileMenuOpened {
  type: typeof CONTENT_PAGE_MOBILE_MENU_OPENED;
  payload: Payload;
}

export const CONTENT_PAGE_MOBILE_MENU_CLOSED = 'CONTENT_PAGE_MOBILE_MENU/CLOSED';

export interface ContentPageMobileMenuClosed {
  type: typeof CONTENT_PAGE_MOBILE_MENU_CLOSED;
  payload: Payload;
}

export const CONTENT_PAGE_GO_BACK_LINK_CLICKED = 'CONTENT_PAGE_GO_BACK_LINK/CLICKED';

export interface ContentPageGoBackLinkClicked {
  type: typeof CONTENT_PAGE_GO_BACK_LINK_CLICKED;
  payload: Payload;
}

export const CONTENT_PAGE_CATEGORY_LINK_CLICKED = 'CONTENT_PAGE_CATEGORY_LINK/CLICKED';

export interface ContentPageCategoryLinkClicked {
  type: typeof CONTENT_PAGE_CATEGORY_LINK_CLICKED;
  payload: Payload;
}

export const CONTENT_PAGE_SUBITEM_LINK_CLICKED = 'CONTENT_PAGE_SUBITEM_LINK/CLICKED';

export interface ContentPageSubItemLinkClicked {
  type: typeof CONTENT_PAGE_SUBITEM_LINK_CLICKED;
  payload: Payload;
}

export type AnalyticsEvents =
  | ContentPageMobileMenuOpened
  | ContentPageMobileMenuClosed
  | ContentPageGoBackLinkClicked
  | ContentPageCategoryLinkClicked
  | ContentPageSubItemLinkClicked;
