import { MEDIA_QUERIES, useMediaQuery } from '@oriola-corporation/ui-components';
import React from 'react';
import { DEVICE_SIZES } from './constants';
import { Context } from './context';

interface ProviderProps {
  forcedSize?: number;
}

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  children,
  forcedSize,
}) => {
  const isXSmall = useMediaQuery(MEDIA_QUERIES.XSMALL, false);
  const isSmall = useMediaQuery(MEDIA_QUERIES.SMALL, true);
  const isMedium = useMediaQuery(MEDIA_QUERIES.MEDIUM, false);

  const size =
    forcedSize ??
    (isXSmall
      ? DEVICE_SIZES.XSMALL
      : isSmall
        ? DEVICE_SIZES.SMALL
        : isMedium
          ? DEVICE_SIZES.MEDIUM
          : DEVICE_SIZES.LARGE);

  return <Context.Provider value={size}>{children}</Context.Provider>;
};
