import { Product } from '@oriola-corporation/product-types';

export const FLYOUT_PRODUCT_LIST_ITEM_VIEWED = 'FLYOUT_PRODUCT_LIST_ITEM/VIEWED';
export interface FlyoutProductListItemViewed {
  type: typeof FLYOUT_PRODUCT_LIST_ITEM_VIEWED;
  payload: {
    product: Product;
    position: number;
  };
}

export const FLYOUT_PRODUCT_LIST_ITEM_CLICKED = 'FLYOUT_PRODUCT_LIST_ITEM/CLICKED';

export interface FlyoutProductListItemClicked {
  type: typeof FLYOUT_PRODUCT_LIST_ITEM_CLICKED;
  payload: {
    product: Product;
    position: number;
  };
}

export const FLYOUT_PRODUCT_LIST_ITEM_CART_CHANGED = 'FLYOUT_PRODUCT_LIST_ITEM/CART_CHANGED';

export interface FlyoutProductListItemCartChanged {
  type: typeof FLYOUT_PRODUCT_LIST_ITEM_CART_CHANGED;
  payload: {
    product: Product;
    quantity: number;
    previousQuantity: number;
  };
}

export type AnalyticsEvents =
  | FlyoutProductListItemViewed
  | FlyoutProductListItemClicked
  | FlyoutProductListItemCartChanged;
