import { RumInitConfiguration } from '@datadog/browser-rum';
import { isServer } from '@packages/gatsby-utils';
import { Script, ScriptStrategy } from 'gatsby';
import React from 'react';
import { maskSensitiveData } from './sensoringUtils';

interface DatadogProps {
  applicationId: string;
  environment: string;
  token: string;
  sampleRate: number;
  serviceName: string;
  allowedTracingUrls: string[];
  enabled?: boolean;
  version: string;
}

declare const window: {
  DD_RUM: {
    init: (config: RumInitConfiguration) => void;
    startSessionReplayRecording: () => void;
    stopSessionReplayRecording: () => void;
    setTrackingConsent: (str: 'granted' | 'not-granted') => void;
    onReady: (callback: () => void) => void;
  };
} & Window;

export const DatadogProvider: React.FC<DatadogProps> = ({
  applicationId,
  environment,
  token,
  sampleRate,
  serviceName,
  allowedTracingUrls,
  enabled,
  version,
}) => {
  if (!enabled && !isServer()) {
    if (window.DD_RUM) {
      if (window.DD_RUM.stopSessionReplayRecording) window.DD_RUM.stopSessionReplayRecording();
      if (window.DD_RUM.setTrackingConsent) window.DD_RUM.setTrackingConsent('not-granted');
    }
    return null;
  }

  return (
    <Script
      strategy={ScriptStrategy.idle}
      src="https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js"
      onLoad={() => {
        if (window.DD_RUM) {
          window.DD_RUM.onReady(() => {
            window.DD_RUM.init({
              trackingConsent: enabled ? 'granted' : 'not-granted',
              applicationId: applicationId,
              clientToken: token,
              site: 'datadoghq.eu',
              service: serviceName,
              env: environment,
              version: version,
              sessionSampleRate: sampleRate,
              sessionReplaySampleRate: sampleRate,
              trackResources: true,
              trackLongTasks: true,
              trackUserInteractions: true,
              allowedTracingUrls: allowedTracingUrls,
              defaultPrivacyLevel: 'mask-user-input',
              enableExperimentalFeatures: ['clickmap'],
              beforeSend: (event) => {
                switch (event.type) {
                  case 'action': {
                    const { target } = event.action;
                    if (target && target.name) {
                      target.name = maskSensitiveData(target.name);
                    }
                  }
                }
                return true;
              },
            });

            window.DD_RUM.startSessionReplayRecording();
          });
        }
      }}
    />
  );
};
