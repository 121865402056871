import { Modal, Paragraph } from '@oriola-corporation/ui-components';
import { setRxStorageValueWithExpiry, useAuth } from '@packages/auth';
import { useDeviceType } from '@packages/device';
import { throttle } from '@packages/utils';
import React, { startTransition, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line kronan-gatsby/no-unpermitted-imports-in-packages
import { useSynchronizedLocalStorage } from '../../../hooks/useSynchronizedLocalStorage';
// eslint-disable-next-line kronan-gatsby/no-unpermitted-imports-in-packages
import { useCart as useRxCart } from '../../../templates/prescription/contexts/Cart';

type ExpiryState = 'ACTIVE' | 'ABOUT_TO_EXPIRE' | 'EXPIRED';

const getCurrentTimestamp = () => {
  return Math.round(new Date().getTime() / 1000);
};

const useLatestInteractionOfUser = () => {
  const [value, setValue] = useSynchronizedLocalStorage<number>(
    'last-active',
    getCurrentTimestamp()
  );
  const updateTimestamp = () => {
    setValue(getCurrentTimestamp());
  };

  const throttledUpdateTimeStamp = throttle(updateTimestamp, FIVE_SECONDS_IN_MS);

  useEffect(() => {
    const interactionEvents = ['focus', 'touchmove', 'click', 'keydown', 'scroll'];

    interactionEvents.forEach((event) => {
      document.addEventListener(event, throttledUpdateTimeStamp);
    });

    return () => {
      interactionEvents.forEach((event) => {
        document.removeEventListener(event, throttledUpdateTimeStamp);
      });
    };
  }, [throttledUpdateTimeStamp]);

  return value;
};

const TEN_SECONDS_IN_MS = 10 * 1000;
const FIVE_SECONDS_IN_MS = 5 * 1000;
const THREE_MINUTES = 3 * 60;
const TEN_MINUTES = 10 * 60;
const TWENTY_MINUTES = 20 * 60;

export const SessionExpiredModal: React.FC = () => {
  const { t } = useTranslation('auth');
  const { isSignedIn, signOut } = useAuth();
  const [activeState, setActiveState] = useSynchronizedLocalStorage<ExpiryState>(
    'active-state',
    'ACTIVE'
  );

  const latestInteraction = useLatestInteractionOfUser();
  const { isMobileOrTablet } = useDeviceType();
  const { cart: rxCart } = useRxCart();

  useEffect(() => {
    if (activeState !== 'EXPIRED' && isSignedIn) {
      const interval = setInterval(() => {
        const timeUntilConsideredInactive = isMobileOrTablet ? TEN_MINUTES : TWENTY_MINUTES;
        const currentTime = getCurrentTimestamp();
        if (activeState === 'ACTIVE') {
          if (
            currentTime - (latestInteraction || currentTime) >
            timeUntilConsideredInactive - THREE_MINUTES
          ) {
            startTransition(() => {
              setActiveState('ABOUT_TO_EXPIRE');
            });
          }
        } else if (activeState === 'ABOUT_TO_EXPIRE') {
          if (currentTime - (latestInteraction || currentTime) > timeUntilConsideredInactive) {
            startTransition(() => {
              setActiveState('EXPIRED');
              setRxStorageValueWithExpiry(
                'cart-rx-was-inactive',
                Boolean(rxCart && rxCart?.numberOfItems > 0)
              );
              signOut();
            });
          }
        }
      }, TEN_SECONDS_IN_MS);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    isMobileOrTablet,
    isSignedIn,
    latestInteraction,
    rxCart,
    setActiveState,
    signOut,
    activeState,
  ]);

  const shouldShowModal = activeState === 'EXPIRED' || activeState === 'ABOUT_TO_EXPIRE';

  const reset = () => setActiveState('ACTIVE');

  const content =
    activeState === 'ABOUT_TO_EXPIRE'
      ? {
          button: t('sessionExpiryModal.aboutToExpiry.continue'),
          title: t('sessionExpiryModal.aboutToExpiry.title'),
          description: t('sessionExpiryModal.aboutToExpiry.description'),
        }
      : {
          button: t('sessionExpiryModal.afterExpiry.close'),
          title: t('sessionExpiryModal.afterExpiry.title'),
          description: t('sessionExpiryModal.afterExpiry.description'),
        };

  return (
    <Modal
      isOpen={shouldShowModal}
      onClose={reset}
      title={content.title}
      actions={[
        {
          label: content.button,
          onClick: reset,
        },
      ]}
    >
      <Paragraph>{content.description}</Paragraph>
    </Modal>
  );
};
