import React from 'react';
import { AppWrapper } from '../src/containers/appWrapper';

interface Args {
  element: React.ReactNode;
}

const wrapElement: (args: Args) => React.ReactNode = ({ element }) => (
  <AppWrapper>{element}</AppWrapper>
);

// eslint-disable-next-line import/no-default-export
export default wrapElement;
