import { Price } from '@apis/expedition';
import { formatFakeRxProduct } from './formatProduct';

export type Variant = 'prescribed' | 'substitution';

export const formatProductOption = (
  price: Pick<Price, 'net' | 'gross'> | null,
  quantity: number,
  variant: Variant,
  availabilityText?: string
) => ({
  ...formatFakeRxProduct(price ? price.net / (100 * quantity) : null, availabilityText),
  metric5: price ? price.gross / (100 * quantity) : 'N/A',
  item_variant: variant === 'prescribed' ? 'prescription' : 'substitution',
});
