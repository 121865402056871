const regexTester =
  (regex: RegExp) =>
  (str: string): boolean =>
    regex.test(str);
export const maskPnr = (str: string): string => {
  const pnrRegex = /\b(\d{6}-\d{4}|\d{8}-\d{4}|\d{12})\b/g;
  return regexTester(pnrRegex) ? str.replace(pnrRegex, '******-****') : str;
};

export const maskEmail = (str: string): string => {
  const emailRegex = /\b(\S+@\S+\.\S+)\b/g;
  return regexTester(emailRegex) ? str.replace(emailRegex, '********@********.***') : str;
};

export const maskPhoneNumber = (str: string): string => {
  const swedishPhoneNumberRegex = /\b(0|00\d{1,3}[-\s]?\d{5,8}|\+46\s?\d{1,3}[-\s]?\d{5,8})\b/g;
  const otherPossiblePhoneNumberRegex = /\b(\d{5,8}[-\s]?\d{5,8})\b/g;

  const phoneNumberRegex = [swedishPhoneNumberRegex, otherPossiblePhoneNumberRegex];

  let theStr = str;

  for (const regex of phoneNumberRegex) {
    if (regexTester(regex)) {
      theStr = theStr.replace(regex, '************');
    }
  }

  return theStr;
};

export const maskEAN = (str: string): string => {
  const eanRegex = /\b(\d{14})\b/g;
  return regexTester(eanRegex) ? str.replace(eanRegex, '************') : str;
};

const chainableMasker = (str: string) => ({
  mask: (...masks: ((str: string) => string)[]) => {
    return masks.reduce((acc, mask) => mask(acc), str);
  },
});

export const maskSensitiveData = (str: string): string => {
  const masker = chainableMasker(str);
  return masker.mask(maskPnr, maskEAN, maskEmail, maskPhoneNumber);
};
