export const MOBILE_MENU_TOGGLED = 'HEADER/MOBILE_MENU_TOGGLED';

export interface MobileMenuToggled {
  type: typeof MOBILE_MENU_TOGGLED;
  payload: {
    id?: string;
    isOpen: boolean;
  };
}

export const LINK_CLICKED = 'HEADER/LINK_CLICKED';

export interface LinkClicked {
  type: typeof LINK_CLICKED;
  payload: {
    id: string;
  };
}

export type AnalyticsEvents = MobileMenuToggled | LinkClicked;
