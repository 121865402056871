import type { Product } from '@oriola-corporation/product-types';
import axios from 'axios';

export type { Product };

export const createApiClient = (baseUrl = '/api/item-web') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getItem: async (itemId: string) => {
      const response = await client.get<Product>(`/items/${itemId}`);

      return response.data;
    },

    getItems: async (itemIds: string[]): Promise<Product[]> => {
      if (!itemIds.length) {
        return [];
      }

      const response = await client.get<Product[]>('/items', {
        params: {
          ids: itemIds.join(','),
        },
      });

      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
