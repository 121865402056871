import axios, { AxiosInstance } from 'axios';
import {
  AnimalPrescription,
  Cart,
  Child,
  CostProtectionResponse,
  Customer,
  GivenPowerOfAttorney,
  Prescription,
  ProductOptionsResponse,
  ReceivedPowerOfAttorney,
} from './types';

export interface AddItemToCartParameters {
  itemId: string;
  quantity: number;
}

const createCartClient = (client: AxiosInstance) => {
  return {
    getCart: async () => {
      const response = await client.get<Cart>(`/cart`);
      return response.data;
    },
    updateQuantity: async (cartItemId: string, quantity: number) => {
      const response = await client.put<Cart>(`/cart/${cartItemId}`, {
        quantity,
      });
      return response.data;
    },
    removeItem: async (cartItemId: string) => {
      const response = await client.delete<Cart>(`/cart/${cartItemId}`);
      return response.data;
    },
  };
};

const createGivenPowersOfAttorneyClient = (client: AxiosInstance) => {
  const createPrefixedUrl = (path?: string) => `/powers-of-attorney-giver${path ?? ''}`;

  return {
    list: async () => {
      const response = await client.get<{ powerOfAttorneyItems: GivenPowerOfAttorney[] }>(
        createPrefixedUrl()
      );
      return response.data;
    },

    create: async (params: {
      receiverPersonalIdNumber: string;
      expirationDate: string;
      termsConfirmed: boolean;
    }) => {
      const response = await client.post<GivenPowerOfAttorney>(createPrefixedUrl(), params);

      return response.data;
    },

    update: async (id: string, params: { expirationDate: string }) => {
      const response = await client.patch<GivenPowerOfAttorney>(
        createPrefixedUrl(`/${id}`),
        params
      );
      return response.data;
    },

    remove: async (id: string) => {
      await client.delete(createPrefixedUrl(`/${id}`));
    },
  };
};

const createReceivedPowersOfAttorneyClient = (client: AxiosInstance) => {
  const createPrefixedUrl = (path?: string) => `/powers-of-attorney-receiver${path ?? ''}`;

  return {
    list: async () => {
      const response = await client.get<{ powerOfAttorneyItems: ReceivedPowerOfAttorney[] }>(
        createPrefixedUrl()
      );
      return response.data;
    },
    getById: async (id: string) => {
      const response = await client.get<ReceivedPowerOfAttorney>(createPrefixedUrl(`/${id}`));
      return response.data;
    },
    update: async (id: string, params: Pick<ReceivedPowerOfAttorney, 'status'>) => {
      const response = await client.patch<ReceivedPowerOfAttorney>(
        createPrefixedUrl(`/${id}`),
        params
      );
      return response.data;
    },
    remove: async (id: string) => {
      await client.delete(createPrefixedUrl(`/${id}`));
    },

    getCostProtection: async (id: string) => {
      const response = await client.get<CostProtectionResponse>(
        createPrefixedUrl(`/${id}/cost-protection`)
      );

      return response.data;
    },
    prescriptions: {
      list: async (id: string) => {
        const response = await client.get<{ prescriptionItems: Prescription[] }>(
          createPrefixedUrl(`/${id}/prescriptions`)
        );

        return response.data;
      },
      getById: async (id: string, prescriptionId: string) => {
        const response = await client.get<Prescription>(
          createPrefixedUrl(`/${id}/prescriptions/${prescriptionId}`)
        );
        return response.data;
      },
      getProductOptions: async (id: string, prescriptionId: string, quantity?: number) => {
        const response = await client.get<ProductOptionsResponse>(
          createPrefixedUrl(`/${id}/prescriptions/${prescriptionId}/product-options`),
          { params: { quantity } }
        );
        return response.data;
      },
      downloadCurrentAsPdf: async (id: string) => {
        const response = await client.get<Blob>(
          createPrefixedUrl(`/${id}/prescriptions/current/pdf`),
          {
            responseType: 'blob',
          }
        );
        return response.data;
      },

      addItemToCart: async (
        id: string,
        prescriptionId: string,
        { itemId, quantity }: AddItemToCartParameters
      ) => {
        const response = await client.post<Cart>(`/powers-of-attorney-receiver/${id}/cart`, {
          prescriptionId,
          prescriptionItemId: itemId,
          quantity,
        });
        return response.data;
      },
    },
  };
};

const createPrescriptionsClient = (client: AxiosInstance) => {
  return {
    list: async () => {
      const response = await client.get<{ prescriptionItems: Prescription[] }>(`/prescriptions`);

      return response.data;
    },
    getById: async (prescriptionId: string) => {
      const response = await client.get<Prescription>(`/prescriptions/${prescriptionId}`);
      return response.data;
    },
    getProductOptions: async (prescriptionId: string, quantity?: number) => {
      const response = await client.get<ProductOptionsResponse>(
        `/prescriptions/${prescriptionId}/product-options`,
        { params: { quantity } }
      );
      return response.data;
    },
    downloadCurrentAsPdf: async () => {
      const response = await client.get<Blob>(`/prescriptions/current/pdf`, {
        responseType: 'blob',
      });
      return response.data;
    },
    downloadCollectedAsPdf: async () => {
      const response = await client.get<Blob>(`/prescriptions/collected/pdf`, {
        responseType: 'blob',
      });
      return response.data;
    },
    addItemToCart: async (
      prescriptionId: string,
      { itemId, quantity }: AddItemToCartParameters
    ) => {
      const response = await client.post<Cart>(`/cart`, {
        prescriptionId,
        prescriptionItemId: itemId,
        quantity,
      });
      return response.data;
    },
  };
};

const createAnimalsClient = (client: AxiosInstance) => {
  return {
    listPrescriptions: async () => {
      const response = await client.get<{ prescriptionItems: AnimalPrescription[] }>(
        `/animal-prescriptions`
      );

      return response.data;
    },
    getPrescriptionById: async (prescriptionId: string) => {
      const response = await client.get<AnimalPrescription>(
        `/animal-prescriptions/${prescriptionId}`
      );

      return response.data;
    },
    getProductOptions: async (prescriptionId: string, quantity?: number) => {
      const response = await client.get<ProductOptionsResponse>(
        `/animal-prescriptions/${prescriptionId}/product-options`,
        { params: { quantity } }
      );
      return response.data;
    },
    downloadCurrentPrescriptionsAsPdf: async () => {
      const response = await client.get<Blob>(`/animal-prescriptions/current/pdf`, {
        responseType: 'blob',
      });
      return response.data;
    },
    addItemToCart: async (
      prescriptionId: string,
      { quantity }: Omit<AddItemToCartParameters, 'itemId'>
    ) => {
      const response = await client.post<Cart>(`/animal/cart`, {
        prescriptionId,
        quantity,
      });
      return response.data;
    },
  };
};

export const createChildrenClient = (client: AxiosInstance) => {
  return {
    list: async () => {
      const response = await client.get<Child[]>(`/children`);
      return response.data;
    },
    getById: async (id: string) => {
      const response = await client.get<Child>(`/children/${id}`);
      return response.data;
    },
    create: async (params: { personalIdNumber: string }) => {
      const response = await client.post<Child>(`/children`, {
        personalIdNumber: params.personalIdNumber,
      });
      return response.data;
    },
    remove: async (id: string) => {
      await client.delete(`/children/${id}`);
    },
    getCostProtection: async (id: string) => {
      const response = await client.get<CostProtectionResponse>(`/children/${id}/cost-protection`);

      return response.data;
    },
    prescriptions: {
      list: async (id: string) => {
        const response = await client.get<{ prescriptionItems: Prescription[] }>(
          `/children/${id}/prescriptions`
        );
        return response.data;
      },
      getById: async (id: string, prescriptionId: string) => {
        const response = await client.get<Prescription>(
          `/children/${id}/prescriptions/${prescriptionId}`
        );
        return response.data;
      },
      getProductOptions: async (id: string, prescriptionId: string, quantity?: number) => {
        const response = await client.get<ProductOptionsResponse>(
          `/children/${id}/prescriptions/${prescriptionId}/product-options`,
          { params: { quantity } }
        );
        return response.data;
      },
      downloadCurrentAsPdf: async (id: string) => {
        const response = await client.get<Blob>(`/children/${id}/prescriptions/current/pdf`, {
          responseType: 'blob',
        });
        return response.data;
      },
      addItemToCart: async (
        id: string,
        prescriptionId: string,
        { itemId, quantity }: AddItemToCartParameters
      ) => {
        const response = await client.post<Cart>(`/children/${id}/cart`, {
          prescriptionId,
          prescriptionItemId: itemId,
          quantity,
        });
        return response.data;
      },
    },
  };
};

export const createApiClient = (baseUrl = '/api/expedition') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getAxiosInstance: () => client,
    getCustomer: async () => {
      const response = await client.get<Customer>('/customer');

      return response.data;
    },
    acceptPersonalTerms: async () => {
      const response = await client.patch<Customer>('/customer', {
        termsConfirmedCustomer: true,
      });

      return response.data;
    },
    acceptAnimalsTerms: async () => {
      const response = await client.patch<Customer>('/customer', {
        termsConfirmedAnimal: true,
      });

      return response.data;
    },
    getCostProtection: async () => {
      const response = await client.get<CostProtectionResponse>('/cost-protection');

      return response.data;
    },
    givenPowersOfAttorney: createGivenPowersOfAttorneyClient(client),
    receivedPowersOfAttorney: createReceivedPowersOfAttorneyClient(client),
    prescriptions: createPrescriptionsClient(client),
    animals: createAnimalsClient(client),
    children: createChildrenClient(client),
    cart: createCartClient(client),
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
