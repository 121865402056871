import React, { createContext, useContext, useState } from 'react';
import ReactDOM from 'react-dom';

const AxeDevToolsContext = createContext<{
  isEnabled: boolean;
  setStatus: (newStatus: boolean) => void;
}>({
  isEnabled: false,
  setStatus: () => {},
});

export const AxeDevToolsToggler: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isAxeDevToolsEnabled, setIsAxeDevToolsEnabled] = useState(false);

  if (
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    isAxeDevToolsEnabled
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }

  return (
    <AxeDevToolsContext.Provider
      value={{ isEnabled: isAxeDevToolsEnabled, setStatus: setIsAxeDevToolsEnabled }}
    >
      {children}
    </AxeDevToolsContext.Provider>
  );
};

export const useAxeDevTools = () => useContext(AxeDevToolsContext);
