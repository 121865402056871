import { AuthMethod } from '@packages/auth';

export const LOGIN = 'RX/LOGIN';

export interface RxLogin {
  type: typeof LOGIN;
  payload: {
    label: string;
    elementType: 'link' | 'button';
    method: AuthMethod;
    location: 'loginPage';
  };
}

export const LOGIN_HEADER = 'RX/LOGIN_HEADER';
export interface RxHeaderLogin {
  type: typeof LOGIN_HEADER;
  payload: {
    label: string;
    elementType: 'link' | 'button';
    method: AuthMethod;
  };
}

export const RX_HEADER_GO_TO_PRESCRIPTIONS = 'RX/HEADER_GO_TO_PRESCRIPTIONS';
export interface RxHeaderGoToPrescriptions {
  type: typeof RX_HEADER_GO_TO_PRESCRIPTIONS;
  payload: {
    label: string;
  };
}

export const LOGOUT = 'RX/LOGOUT';

export interface RxLogout {
  type: typeof LOGOUT;
}

export type AnalyticsEvents = RxLogin | RxLogout | RxHeaderLogin | RxHeaderGoToPrescriptions;
