export const throttle = <T extends unknown[]>(
  callback: (...args: T) => void,
  wait: number
): ((...args: T) => void) => {
  let timeoutId: NodeJS.Timeout | null = null;
  let lastExecuted = 0;

  return (...args: T) => {
    const now = Date.now();
    const elapsed = now - lastExecuted;

    const execute = () => {
      lastExecuted = Date.now();
      callback(...args);
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (elapsed > wait) {
      execute();
    } else {
      timeoutId = setTimeout(execute, wait - elapsed);
    }
  };
};
