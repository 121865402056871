export const ARTICLE_LINK_CLICKED = 'ARTICLE_LINK/CLICKED';

export interface ArticleLinkClicked {
  type: typeof ARTICLE_LINK_CLICKED;
  payload: {
    textTitle: string;
    linkText: string;
  };
}

export type AnalyticsEvents = ArticleLinkClicked;
