import { useApi } from '@packages/api';
import { useAuth } from '@packages/auth';
import { buildMemberQueryKey } from '@packages/member';
import { useQuery } from '@tanstack/react-query';

const useGetMember = () => {
  const { member } = useApi();
  const { isSignedIn } = useAuth();
  return useQuery(buildMemberQueryKey(), () => member.getMember(), {
    refetchOnWindowFocus: false,
    enabled: isSignedIn,
  });
};

const useGetMemberStatus = () => {
  const { member } = useApi();
  const { isSignedIn } = useAuth();
  return useQuery(buildMemberQueryKey('status'), () => member.getMemberStatus(), {
    refetchOnWindowFocus: false,
    enabled: isSignedIn,
  });
};

export const MemberApi = {
  useGetMember,
  useGetMemberStatus,
};
