import { EXPIRED_PRESCRIPTIONS_TOGGLED } from '../../../../../../components/expedition/prescriptions/analytics';
import { Tracker } from '../../../types';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const expiredPrescriptionsToggleTracker: Tracker = createSimpleTracker({
  [EXPIRED_PRESCRIPTIONS_TOGGLED]: ({ event }) => ({
    event: 'Toggle',
    event_info: {
      event_name: 'toggle',
      user_interaction: event.payload.isToggled ? 'show' : 'hide',
      link_text: convertTextToSlug(event.payload.label),
    },
  }),
});
