import { Product } from '@oriola-corporation/product-types';
import { Cart } from '@packages/cart';

export const CART_VIEWED = 'CART/VIEWED';

export interface CartViewed {
  type: typeof CART_VIEWED;
  payload: {
    cart: Cart;
  };
}

export const CART_ITEM_CLICKED = 'CART/ITEM_CLICKED';

export interface CartItemClicked {
  type: typeof CART_ITEM_CLICKED;
  payload: {
    item: Product;
  };
}

export const CART_ITEM_CHANGED = 'CART/ITEM_CHANGED';

export interface CartItemChanged {
  type: typeof CART_ITEM_CHANGED;
  payload: {
    previousQuantity: number;
    quantity: number;
    product: Product;
  };
}

export const NAVIGATED_TO_CHECKOUT = 'CART/NAVIGATED_TO_CHECKOUT';

export interface NavigatedToCheckout {
  type: typeof NAVIGATED_TO_CHECKOUT;
  payload: {
    source: 'BUTTON_IN_CART' | 'CART_ICON';
  };
}

export type AnalyticsEvents = CartViewed | CartItemClicked | CartItemChanged | NavigatedToCheckout;
