export interface RequestRow {
  sku: string;
  quantity: number;
}

export enum DeliveryParametersAttribute {
  IdRequired = 'idRequired',
  RequiresCooling = 'cool',
  AdultOnly = 'adultOnly',
  Ecological = 'ecological',
}

export interface DeliveryInformationItem {
  sku: string;
  attributes: DeliveryParametersAttribute[];
}

export interface DeliveryParametersCollection {
  earliestShippingDate: string;
  items: DeliveryInformationItem[];
}
