export const LINK_BLOCK_CLICKED = 'LINK_BLOCK/CLICKED';

export interface LinkBlockClicked {
  type: typeof LINK_BLOCK_CLICKED;
  payload: {
    textTitle: string;
    linkText: string;
  };
}

export type AnalyticsEvents = LinkBlockClicked;
