import axios from 'axios';
import { Availability, RemoveWatcher } from './types';

export const createApiClient = (baseUrl = '/api/availability') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  return {
    getAvailabilityForProducts: async (productIds: string[]) => {
      const response = await client.post<Availability[]>('/item', productIds);

      return response.data;
    },
    removeWatcher: async (id: string) => {
      const response = await client.delete<RemoveWatcher[]>(`/backinstock/${id}`);

      return response.data;
    },

    postBackInStock: async (itemId: string, email: string) => {
      const response = await client.post<Availability[]>('/backinstock', {
        itemId,
        email,
      });

      return response.data;
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
