import { isServer } from '@packages/gatsby-utils';
import Cookies from 'js-cookie';

export const isApp = (): boolean =>
  Boolean(
    !!Cookies.get('kronan-app-only') &&
      !isServer() &&
      window &&
      window.location &&
      window.location.href &&
      window.location.href.includes('external')
  );
