import { ProductCardProduct } from '../productCard/types';

export const PRODUCT_LIST_ITEM_VIEWED = 'PRODUCT_LIST_ITEM_VIEWED';
export interface ProductListItemViewed {
  type: typeof PRODUCT_LIST_ITEM_VIEWED;
  payload: {
    product: ProductCardProduct;
    position: number;
  };
}

export const PRODUCT_LIST_ITEM_CLICKED = 'PRODUCT_LIST_ITEM_CLICKED';

export interface ProductListItemClicked {
  type: typeof PRODUCT_LIST_ITEM_CLICKED;
  payload: {
    product: ProductCardProduct;
    position: number;
  };
}

export type AnalyticsEvents = ProductListItemViewed | ProductListItemClicked;
