import { PRESCRIBED_OPTION_PRODUCT_DETAIL } from '../../../../../../components/expedition/prescriptions/buyableOptions/analytics';
import {
  PRODUCT_PAGE_VIEWED,
  PRODUCT_PAGE_FIND_IN_PHARMACY_MODAL_OPENED,
} from '../../../../../../components/pages/productPage/analytics';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import { formatFakeRxProduct, formatProduct, toTrackableProduct } from '../utils/formatProduct';

import { parseListNameFromMetadata } from '../utils/parseListNameFromMetadata';

const createBaseEvent = <T>({ list, products }: { list: string; products: T[] }) => ({
  event: 'productDetail',
  platform: 'K2',
  ecommerce: {
    detail: {
      actionField: { list },
      products,
    },
  },
});

export const productDetailTracker = createSimpleTracker({
  [PRODUCT_PAGE_VIEWED]: ({ event, metadata }) =>
    createBaseEvent({
      list: (metadata.pageType as string) || 'N/A',
      products: [formatProduct(toTrackableProduct(event.payload.product))],
    }),
  [PRODUCT_PAGE_FIND_IN_PHARMACY_MODAL_OPENED]: ({ event }) => ({
    event: 'hitta_varan',
    event_info: {
      user_interaction: 'open_modal',
      event_name: 'hitta_varan',
      link_text: convertTextToSlug(event.payload.linkText),
    },
  }),
  [PRESCRIBED_OPTION_PRODUCT_DETAIL]: ({ metadata, event }) => {
    const { productOption, hasSubstitution } = event.payload;
    const list = `${parseListNameFromMetadata(metadata)}${
      hasSubstitution ? '_prescription_list' : ''
    }`;

    return createBaseEvent({
      list: list,
      products: [
        {
          ...formatFakeRxProduct(null, productOption.buyableStatus.reasonTitle),
          metric5: null,
          item_variant: 'prescription',
        },
      ],
    });
  },
});
