import { PRESCRIPTION_CARD_VIEWED } from '../../../../../../components/expedition/analytics';
import { SUBSTITUTIONS_OPTIONS_OPTION_VIEWED } from '../../../../../../components/expedition/prescriptions/buyableOptions/analytics';
import { FLYOUT_PRODUCT_LIST_ITEM_VIEWED } from '../../../../../../components/product/flyoutProductList/analytics';
import { PRODUCT_CARD_VIEWED } from '../../../../../../components/product/productCard/analytics';
import { PRODUCT_LIST_ITEM_VIEWED } from '../../../../../../components/product/productListItem/analytics';
import { PRODUCT_BUNDLE_VIEWED } from '../../../../../../components/productBundle/analytics';
import { Tracker } from '../../../types';
import {
  formatFakeRxProduct,
  formatProduct,
  GAFormattedProduct,
  toTrackableProduct,
} from '../utils/formatProduct';
import { formatProductOption } from '../utils/formatProductOption';
import { parseListNameFromMetadata } from '../utils/parseListNameFromMetadata';
import { trackEvent } from '../utils/trackEvent';

let queue: Array<{
  product: GAFormattedProduct & Record<string, string | number | boolean>;
  position: number;
  list: string;
}> = [];

let debounceTimeout: ReturnType<typeof setTimeout> | null = null;

const scheduleTracking = () => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }

  debounceTimeout = setTimeout(() => {
    const currentQueue = queue;
    queue = [];

    trackEvent({
      platform: 'K2',
      event: 'productImpressions',
      ecommerce: {
        impressions: currentQueue.map(({ product, position, list }) => ({
          ...product,
          position,
          list,
        })),
        _clear: true,
      },
    });

    debounceTimeout = null;
  }, 200);
};

export const productImpressionTracker: Tracker = ({ event, metadata }) => {
  switch (event.type) {
    case FLYOUT_PRODUCT_LIST_ITEM_VIEWED:
    case PRODUCT_LIST_ITEM_VIEWED: {
      const { product: rawProduct, position } = event.payload;
      const product = formatProduct(toTrackableProduct(rawProduct));
      const list = (metadata.pageType as string) || 'N/A';
      queue.push({ product, position, list });
      scheduleTracking();
      break;
    }
    case PRODUCT_CARD_VIEWED: {
      const { product: rawProduct, position, list } = event.payload;
      const product = formatProduct(toTrackableProduct(rawProduct));
      const listValue = (metadata.pageType as string) || list || 'N/A';
      queue.push({ product, position, list: listValue });
      scheduleTracking();
      break;
    }
    case PRESCRIPTION_CARD_VIEWED: {
      const { position } = event.payload;
      const list = parseListNameFromMetadata(metadata);
      queue.push({ product: formatFakeRxProduct(), position, list });
      scheduleTracking();
      break;
    }
    case PRODUCT_BUNDLE_VIEWED: {
      const { products } = event.payload;
      if (products.length > 0) {
        const list = 'often-bought-together';
        products.forEach((product, index) => {
          queue.push({
            product: formatProduct(toTrackableProduct(product)),
            position: index + 1,
            list,
          });
        });
        scheduleTracking();
      }
      break;
    }
    case SUBSTITUTIONS_OPTIONS_OPTION_VIEWED: {
      const { position, productOption, quantity, variant, hasSubstitution } = event.payload;
      const list = `${parseListNameFromMetadata(metadata)}${
        hasSubstitution ? '_prescription_list' : ''
      }`;
      queue.push({
        product: formatProductOption(
          null,
          quantity,
          variant,
          productOption.buyableStatus.reasonTitle
        ),
        list: list,
        position,
      });
      scheduleTracking();
    }
  }
};
