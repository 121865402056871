import {
  elevate,
  FacetParams,
  localStorageBackedSession,
  SortType,
  Product as VoyadoProduct,
} from '@apptus/esales-api';
import { SustainabilityBadge } from '@oriola-corporation/product-types';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import { PRODUCTS_PER_PAGE } from '@packages/search';
import { isDefined } from '@packages/utils';
import { ProductCardProduct } from '../../components/product/productCard/types';
import { observeCookies } from '../../cookiebot';
import { ProductPageRecommendation } from './types';

const CONTENTLIST_ID = 'ld-contentList';
const EXCLUDE_RX_RULE = 'rule excl custom.rX { "Ja" }';
export const HOME_PAGE = 'Webb hem';
export const NUMBER_OF_PRODUCTS_PER_PAGE = PRODUCTS_PER_PAGE;
export const CHECKOUT = 'Webb kassa';
export const WEB_CHECKOUT_ID = 'webb-kassa';

const searchOrigins = [
  'ORGANIC',
  'PHRASE_SUGGEST',
  'DID_YOU_MEAN',
  'UNDO_AUTO_CORRECT',
  'RELATED_SEARCHES',
  'RECENT_SEARCH',
] as const;

type SearchOrigin = (typeof searchOrigins)[number];

const sortTypes: SortType[] = [
  'RELEVANCE',
  'NEWEST_FIRST',
  'PRICE_ASCENDING',
  'PRICE_DESCENDING',
  'DISCOUNT',
  'RATING',
  'NAME',
  'LENGTH_INCREASING',
  'LENGTH_DECREASING',
  'WIDTH_INCREASING',
  'WIDTH_DECREASING',
  'HEIGHT_INCREASING',
  'HEIGHT_DECREASING',
  'DEPTH_INCREASING',
  'DEPTH_DECREASING',
  'VOLUME_INCREASING',
  'VOLUME_DECREASING',
  'WEIGHT_INCREASING',
  'WEIGHT_DECREASING',
  'TITLE',
];

// Type guard to check if a value is a valid SearchOrigin
export const isSearchOrigin = (value: string | null): value is SearchOrigin => {
  if (!value) return false;

  return searchOrigins.includes(value as SearchOrigin);
};

export const isSortType = (value: string): value is SortType => {
  return sortTypes.includes(value as SortType);
};

//  initialize the session keys for a user if they are not set
//  mocked session is used for tests and for users that have not accepted preferences cookies
const mockedSession = () => ({
  customerKey: String(crypto.randomUUID()),
  sessionKey: String(crypto.randomUUID()),
});

export let voyadoSession = mockedSession;

export const PRODUCT_PAGE_UPSELL = 'productUpsell';
export const PRODUCT_PAGE_ALTERNATIVES = 'productAlternatives';

export const createVoyadoApiClient = () => {
  let client = elevate({
    clusterId: getConfigurationVariable('VOYADO_CLUSTER_ID') || 'nada', // nada for tests
    market: 'se',
    locale: 'sv-SE',
    touchpoint: 'desktop',
    session: voyadoSession,
  });

  if (!isServer()) {
    // If the user has accepted preferences cookies, we use the local storage backed session
    const onCookiesLoad = (hasAccepted: boolean) => {
      voyadoSession = hasAccepted ? localStorageBackedSession() : mockedSession;

      // Reinitialize the client with the new session
      client = elevate({
        clusterId: getConfigurationVariable('VOYADO_CLUSTER_ID') || 'nada', // nada for tests
        market: 'se',
        locale: 'sv-SE',
        touchpoint: 'desktop',
        session: voyadoSession,
      });
    };

    observeCookies(onCookiesLoad, 'preferences');
  }

  return {
    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/queries/search-page/
    getSearchResult: ({
      searchQuery,
      origin,
      skip,
      sort,
      facets,
      limit = NUMBER_OF_PRODUCTS_PER_PAGE,
      notify = true,
    }: {
      searchQuery: string;
      origin: SearchOrigin;
      skip: number;
      sort?: SortType;
      facets?: FacetParams;
      limit?: number;
      notify?: boolean;
    }) => {
      return client.query.searchPage(
        {
          q: searchQuery,
          sort,
          facets,
          limit,
          skip,
          notify,
          origin,
          presentCustom: [
            'ean',
            'nplID',
            'itemType',
            'rX',
            'subTitle',
            'descriptiveName',
            'productNumber',
            'promotionRibbon',
            'promotionPrice',
            'hasPromotion',
            'substance',
            'marketingSensitive',
            'sustainabilityBadges',
            'newProduct',
            'basePrice',
            'minPriceMonth',
            'rating',
          ],
        },
        {
          contentLists: [{ id: CONTENTLIST_ID }],
        }
      );
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/queries/autocomplete/
    getSearchSuggestions: ({ searchQuery, notify }: { searchQuery?: string; notify: boolean }) => {
      return client.query.autocomplete(
        {
          q: searchQuery ?? '',
          notify,
          presentCustom: [
            'subTitle',
            'descriptiveName',
            'promotionPrice',
            'itemType',
            'minPriceMonth',
          ],
        },
        {
          contentLists: [{ id: CONTENTLIST_ID }],
        }
      );
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/queries/cart-page/
    getCartRecommendations: (cart: string[]) => {
      return client.query.cartPage(
        { cart },
        {
          cart: {
            include: true,
          },
          recommendationLists: [
            {
              id: 'cart',
              algorithm: 'CART',
              limit: 10,
              params: {
                cart,
              },
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/queries/landing-page/
    getLandingPageRecommendations: ({
      listId,
      pageReference = HOME_PAGE,
    }: ProductPageRecommendation) => {
      return client.query.landingPage(
        { pageReference },
        {
          recommendationLists: [
            {
              id: listId,
              limit: 10,
              algorithm: 'TOP_PRODUCTS',
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/queries/product-page/
    getProductPageRecommendations: (productKey: string) => {
      return client.query.productPage(
        { productKey },
        {
          recommendationLists: [
            {
              id: PRODUCT_PAGE_UPSELL,
              limit: 10,
              algorithm: 'UPSELL',
              params: {
                productKey,
              },
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
            {
              id: PRODUCT_PAGE_ALTERNATIVES,
              limit: 10,
              algorithm: 'ALTERNATIVES',
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/notifications/click/
    postClickNotification: (ticket: string) => {
      return client.notify.click(ticket);
    },

    // https://docs.apptus.com/elevate/4/integration/api/specifications/storefront/v3/notifications/add-to-cart/
    postAddToCartNotification: (ticket: string) => {
      return client.notify.addToCart(ticket);
    },
  };
};

export type VoyadoApiClient = ReturnType<typeof createVoyadoApiClient>;

export const convertVoyadoProductToProduct = (product: VoyadoProduct): ProductCardProduct => {
  const SUSTAINABILITY_BADGES: Record<string, SustainabilityBadge | undefined> = {
    Välvald: {
      title: 'Välvald',
      description:
        'Receptfria läkemedel med symbolen Välvald är från företag som är mer transparenta med sitt hållbarhetsarbete och ställer krav på ansvarsfull tillverkning.',
      logoUrl: 'https://assets.kronansapotek.io/icons/valvald-badge.svg',
      link: {
        url: 'https://www.kronansapotek.se/om-oss/hallbarhet/valvald/',
        text: 'Mer om guiden Välvald',
      },
    },
  };

  const substance = product?.custom?.substance?.[0].label;
  const isRestricted = substance === 'diklofenak' || substance === 'diklofenakdietylamin';

  const enrichSustainabilityBadges = (badgeNames: string[]): SustainabilityBadge[] =>
    badgeNames.map((badgeName) => SUSTAINABILITY_BADGES[badgeName]).filter(isDefined);

  const convertToNumber = (stringNum: string | undefined) => {
    if (stringNum !== undefined) {
      return Number(stringNum);
    }
  };
  // return a product object with the properties from voyado product
  return {
    url: product.link, // not used
    productNumber: product.key,
    title: product.title,
    subTitle: product?.custom?.subTitle?.[0].label ?? '',
    atcCode: '',
    promotionPrice: product.sellingPrice.max,
    promotionRibbon: product?.custom?.promotionRibbon?.[0].label ?? '',
    hasPromotion: product?.custom?.hasPromotion?.[0].label === 'Ja',
    price: product.listPrice.max,
    minPriceMonth: convertToNumber(product?.custom?.minPriceMonth?.[0].label),
    vatRate: 0, // not used
    basePrice: convertToNumber(product.custom?.basePrice?.[0].label),
    productCategory: [{ url: '', name: '' }], // not used
    itemType: product?.custom?.itemType?.[0].label ?? '',
    substance: product?.custom?.substance?.[0].label ?? '',
    sustainabilityBadges: enrichSustainabilityBadges([
      product?.custom?.sustainabilityBadges?.[0].label ?? '',
    ]),
    imageIndex: ['0'],
    breadcrumb: [], // not used
    brandName: product.brand,
    descriptiveName: product?.custom?.descriptiveName?.[0].label, // not used
    rating: convertToNumber(product?.custom?.rating?.[0].label),
    score: 0, // not used
    availability: product.inStock
      ? { text: '', buyable: true, status: '' }
      : { text: '', buyable: false, status: '' },
    marketingSensitive: product?.custom?.marketingSensitive?.[0].label === 'Ja',
    restriction: {
      itemId: product.key,
      substance: substance,
      isRestricted: isRestricted,
    },
    newProduct: product.custom?.newProduct?.[0]?.label === 'Ja',
  };
};
