import { PRESCRIPTION_PAGE_TYPE } from '../../../../../../templates/prescription/pages/rxPageAnalyticsTypes';
import { Metadata } from '../../../types';

export const parseListNameFromMetadata = (metadata: Metadata) => {
  switch (metadata.pageType) {
    case PRESCRIPTION_PAGE_TYPE.MY_PRESCRIPTIONS.INDEX:
      return 'rmp';
    case PRESCRIPTION_PAGE_TYPE.MY_PRESCRIPTIONS.PRESCRIPTION:
      return 'ppmp';
    case PRESCRIPTION_PAGE_TYPE.POWERS_OF_ATTORNEY.INDEX:
      return 'rp';
    case PRESCRIPTION_PAGE_TYPE.POWERS_OF_ATTORNEY.DETAILS:
      return 'rp';
    case PRESCRIPTION_PAGE_TYPE.POWERS_OF_ATTORNEY.PRESCRIPTION:
      return 'ppp';
    case PRESCRIPTION_PAGE_TYPE.CHILDREN.INDEX:
      return 'rc';
    case PRESCRIPTION_PAGE_TYPE.CHILDREN.DETAILS:
      return 'rc';
    case PRESCRIPTION_PAGE_TYPE.CHILDREN.PRESCRIPTION:
      return 'ppc';
    case PRESCRIPTION_PAGE_TYPE.ANIMALS.INDEX:
      return 'ra';
    case PRESCRIPTION_PAGE_TYPE.ANIMALS.PRESCRIPTION:
      return 'ppa';
    default:
      return 'N/A';
  }
};
