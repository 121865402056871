import {
  RX_MENU_ITEM_CLICKED,
  RX_MENU_SUBMENU_ITEM_CLICKED,
  RX_MOBILE_MENU_CLOSED,
  RX_MOBILE_MENU_OPENED,
} from '../../../../../../components/expedition/analytics';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({
  userInteraction,
  navigationSelection,
}: {
  userInteraction: string;
  navigationSelection?: string;
  eventName?: string;
}) => ({
  event: 'main_menu',
  event_info: {
    event_name: 'rx_menu',
    user_interaction: userInteraction,
    navigation_selection: navigationSelection ? convertTextToSlug(navigationSelection) : 'N/A',
  },
});

export const prescriptionMenuTracker = createSimpleTracker({
  [RX_MENU_ITEM_CLICKED]: ({ event, metadata }) =>
    createBaseEvent({
      userInteraction: 'link_click',
      navigationSelection: `${event.payload.id}_${metadata.navigationOrigin}`,
    }),
  [RX_MENU_SUBMENU_ITEM_CLICKED]: ({ event, metadata }) =>
    createBaseEvent({
      userInteraction: 'link_click',
      navigationSelection: `sub_item_${event.payload.id}_${metadata.navigationOrigin}`,
    }),
  [RX_MOBILE_MENU_OPENED]: ({ event }) =>
    createBaseEvent({
      userInteraction: 'open_menu',
      navigationSelection: `${event.payload.name}`,
    }),
  [RX_MOBILE_MENU_CLOSED]: ({ metadata }) =>
    createBaseEvent({
      userInteraction: 'close_menu',
      navigationSelection: `${metadata.navigationOrigin}`,
    }),
});
