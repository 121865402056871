import { Availability } from '@apis/availability';
import { createBatchLoaderContext } from '@oriola-corporation/ui-components';
import { useApi } from '@packages/api';
import React from 'react';
import ReactDOM from 'react-dom';

const { Provider: BatchProvider, useItem } = createBatchLoaderContext<Availability>(
  (availability) => availability.productNumber,
  ReactDOM.unstable_batchedUpdates
);

export const ProductAvailabilityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { availability } = useApi();

  return (
    <BatchProvider loadItems={availability.getAvailabilityForProducts} debounce={100}>
      {children}
    </BatchProvider>
  );
};

export const useProductAvailability = (itemId: string) => {
  const { data, isLoading, hasErrors } = useItem(itemId);

  return {
    data,
    isLoading,
    hasErrors,
  };
};
