// eslint-disable-next-line kronan-gatsby/no-unpermitted-imports-in-packages
import type { ExposedEnvVariable } from '../../../config/utils/loadEnv';

export { FeatureFlagsProvider, useFeatureEnabled, useFeatureFlags } from './featureFlags/provider';

type PublicConfig = Record<`GATSBY_${ExposedEnvVariable}`, string>;

const getPublicConfig = (): PublicConfig => {
  const publicConfig = process.env.PUBLIC_CONFIG || {};

  if (typeof publicConfig === 'string') {
    return JSON.parse(publicConfig) as PublicConfig;
  }

  return publicConfig as PublicConfig;
};

const PUBLIC_CONFIG = getPublicConfig();

export const getConfigurationVariable = (name: ExposedEnvVariable) =>
  PUBLIC_CONFIG[`GATSBY_${name}`];

export const STATIC_CONFIG = {
  ASSETS_BASE_URL: 'https://assets.kronansapotek.se',
};
