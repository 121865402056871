import { Cart, GoogleAnalyticsData } from '@packages/cart';
import { AnalyticsEvents as MedicalCounselingAnalyticsEvents } from './steps/MedicalCounseling/analytics';

export const CHECKOUT_PAGE_VIEWED = 'CHECKOUT/VIEWED';

export interface CheckoutPageViewed {
  type: typeof CHECKOUT_PAGE_VIEWED;
  payload: {
    cart: Cart;
  };
}

export const ORDER_CONFIRMED = 'CHECKOUT/ORDER_CONFIRMED';

export interface OrderConfirmed {
  type: typeof ORDER_CONFIRMED;
  payload: {
    summary: GoogleAnalyticsData;
    url: string;
  };
}

export const EXTERNAL_ITEMS_ADDED_TO_CART = 'EXTERNAL/ITEMS_ADDED_TO_CART';

export interface ExternalItemsAddedToCart {
  type: typeof EXTERNAL_ITEMS_ADDED_TO_CART;
  payload: {
    itemIds: string[];
  };
}

export const CHECKOUT_PAYMENT_VIEWED = 'CHECKOUT/PAYMENT_VIEWED';
export interface CheckoutPaymentViewed {
  type: typeof CHECKOUT_PAYMENT_VIEWED;
}

export const CHECKOUT_BILLING_CHANGED = 'CHECKOUT/BILLING_CHANGED';
export interface CheckoutBillingChanged {
  type: typeof CHECKOUT_BILLING_CHANGED;
}

export const CHECKOUT_SHIPPING_CHANGED = 'CHECKOUT/SHIPPING_CHANGED';
export interface CheckoutShippingChanged {
  type: typeof CHECKOUT_SHIPPING_CHANGED;
}

export const CHECKOUT_DELIVERY_VIEWED = 'CHECKOUT/DELIVERY_VIEWED';
export interface CheckoutDeliveryViewed {
  type: typeof CHECKOUT_DELIVERY_VIEWED;
}

export type AnalyticsEvents =
  | CheckoutPageViewed
  | OrderConfirmed
  | ExternalItemsAddedToCart
  | MedicalCounselingAnalyticsEvents
  | CheckoutPaymentViewed
  | CheckoutBillingChanged
  | CheckoutShippingChanged
  | CheckoutDeliveryViewed;
