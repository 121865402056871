export const SORT_OPTION_SELECTED = 'SEARCH/SORT_OPTION_SELECTED';
export type SortOptionSelected = {
  type: typeof SORT_OPTION_SELECTED;
  payload: {
    id: string;
    label: string;
    previous: {
      id: string;
      label: string;
    };
  };
};

export const SORT_OPTIONS_DROPDOWN_TOGGLED = 'SEARCH/SORT_OPTIONS_DROPDOWN_TOGGLED';
export type SortOptionsDropdownToggled = {
  type: typeof SORT_OPTIONS_DROPDOWN_TOGGLED;
  payload: {
    isOpen: boolean;
    id: string;
    label: string;
  };
};

export const SORT_OPTIONS_MODAL_OPENED = 'SEARCH/SORT_OPTIONS_MODAL_OPENED';
export type SortOptionsModalOpened = {
  type: typeof SORT_OPTIONS_MODAL_OPENED;
  payload: {
    id: string;
    label: string;
  };
};

export const SORT_OPTIONS_MODAL_CLOSED = 'SEARCH/SORT_OPTIONS_MODAL_CLOSED';
export type SortOptionsModalClosed = {
  type: typeof SORT_OPTIONS_MODAL_CLOSED;
  payload: {
    id: string;
    label: string;
    reason: 'apply' | 'close';
  };
};

export const TAGS_FILTER_OPTION_TOGGLED = 'SEARCH/TAGS_FILTER_OPTION_TOGGLED';
export type TagsFilterOptionToggled = {
  type: typeof TAGS_FILTER_OPTION_TOGGLED;
  payload: {
    id: string;
    label: string;
    option: {
      id: string;
      label: string;
      count: number;
    };
    isSelected: boolean;
  };
};

export const TAGS_FILTER_DROPDOWN_TOGGLED = 'SEARCH/TAGS_FILTER_DROPDOWN_TOGGLED';
export type TagsFilterDropdownToggled = {
  type: typeof TAGS_FILTER_DROPDOWN_TOGGLED;
  payload: {
    id: string;
    label: string;
    isOpen: boolean;
  };
};

export const SEARCH_FILTERS_MODAL_OPENED = 'SEARCH/SEARCH_FILTERS_MODAL_OPENED';
export type SearchFiltersModalOpened = {
  type: typeof SEARCH_FILTERS_MODAL_OPENED;
};

export const SEARCH_FILTERS_MODAL_CLOSED = 'SEARCH/SEARCH_FILTERS_MODAL_CLOSED';
export type SearchFiltersModalClosed = {
  type: typeof SEARCH_FILTERS_MODAL_CLOSED;
  payload: {
    reason: 'apply' | 'close';
  };
};

export const SEARCH_FILTERS_CLEARED = 'SEARCH/SEARCH_FILTERS_CLEARED';
export type SearchFiltersCleared = {
  type: typeof SEARCH_FILTERS_CLEARED;
};

export type AnalyticsEvents =
  | SortOptionSelected
  | SortOptionsDropdownToggled
  | SortOptionsModalOpened
  | SortOptionsModalClosed
  | TagsFilterOptionToggled
  | TagsFilterDropdownToggled
  | SearchFiltersModalOpened
  | SearchFiltersModalClosed
  | SearchFiltersCleared;
