export const PRESCRIPTION_PAGE_TYPE = {
  MY_PRESCRIPTIONS: {
    INDEX: 'my-prescriptions',
    PRESCRIPTION: 'my-prescriptions-prescription',
  },
  POWERS_OF_ATTORNEY: {
    INDEX: 'powers-of-attorney',
    DETAILS: 'powers-of-attorney-details',
    PRESCRIPTION: 'powers-of-attorney-prescription',
  },
  CHILDREN: {
    INDEX: 'children',
    DETAILS: 'children-details',
    PRESCRIPTION: 'children-prescription',
  },
  ANIMALS: {
    INDEX: 'animals',
    PRESCRIPTION: 'animals-prescription',
  },
};
