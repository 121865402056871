import {
  getValueFromStorage,
  setValueToStorage,
  removeKeyFromStorage,
  uniqueArray,
} from '@packages/utils';
import { AnalyticsList } from './types';

export const addToStorage = ({ item, key }: { item: number | string | string[]; key: string }) => {
  const analyticsList = getValueFromStorage<AnalyticsList>('analytics_list');
  const productList = analyticsList ? analyticsList : {};
  const keyDoesExist = !!productList[key];

  const updatedProductList = keyDoesExist
    ? { ...productList, [key]: uniqueArray([...productList[key], item].flat(), (l) => l) }
    : { ...productList, [key]: [item].flat() };

  setValueToStorage<AnalyticsList>('analytics_list', updatedProductList);
};

export const clearListFromStorage = ({ list }: { list: string }) => {
  const analyticsList = getValueFromStorage<AnalyticsList>('analytics_list') ?? {};
  delete analyticsList[list];
  setValueToStorage<AnalyticsList>('analytics_list', analyticsList);
};

export const getListFromStorage = ({ key }: { key: string }) => {
  const analyticsList = getValueFromStorage<AnalyticsList>('analytics_list') ?? {};
  return analyticsList[key] ?? [];
};

export const getListByValueFromStorage = ({ value }: { value: string | number }) => {
  const analyticsList = getValueFromStorage<AnalyticsList>('analytics_list') ?? {};
  return Object.keys(analyticsList).find((key) => {
    const analyticsListValue = analyticsList[key];
    if (
      (Array.isArray(analyticsListValue) || typeof analyticsListValue === 'string') &&
      typeof analyticsListValue.includes !== 'undefined'
    )
      return analyticsList[key].includes(value as never);

    return false;
  });
};

export const removeAnalyticsStorage = (): void => removeKeyFromStorage('analytics_list');
