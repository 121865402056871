import { TEENAGER_COPY_VIEWED } from '../../../../../../components/expedition/analytics';
import { Tracker } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';

const createBaseEvent = ({ eventName, textTitle }: { eventName: string; textTitle: string }) => ({
  event: 'message',
  event_info: {
    event_name: eventName,
    text_title: textTitle,
  },
});

export const teenagerCopyViewedTracker: Tracker = createSimpleTracker({
  [TEENAGER_COPY_VIEWED]: ({ event }) =>
    createBaseEvent({
      eventName: 'modal_message_impression',
      textTitle: event.payload.title,
    }),
});
