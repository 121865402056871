import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

interface AuthConfig {
  getCurrentToken: () => string | undefined;
  refreshToken: () => Promise<string | undefined>;
  isUnauthenticatedResponse?: (error: AxiosError) => boolean;
  onUnauthorizedError?: () => void;
  convertError?: (error: unknown) => void;
  headerKey?: string;
  headerPrefix?: string;
}

const DEFAULT_CONFIG: Required<AuthConfig> = {
  getCurrentToken: () => undefined,
  refreshToken: () => Promise.resolve(undefined),
  convertError: (error: unknown) => Promise.reject(error),
  isUnauthenticatedResponse: (error: AxiosError) => {
    return !!error.response && [403, 401].includes(error.response?.status);
  },
  onUnauthorizedError: () => {},
  headerKey: 'Authorization',
  headerPrefix: 'Bearer',
};

interface ConfigWithTokenRetry extends AxiosRequestConfig {
  withFreshToken?: boolean;
}

export const attachAuthAxiosInterceptors = (axiosClient: AxiosInstance, authConfig: AuthConfig) => {
  const configWithDefaults: Required<AuthConfig> = {
    ...DEFAULT_CONFIG,
    ...authConfig,
  };

  const appendTokenToConfig = (config: AxiosRequestConfig, token: string) => {
    if (config.headers) {
      config.headers[configWithDefaults.headerKey] = `${configWithDefaults.headerPrefix} ${token}`;
    } else {
      config.headers = {
        [configWithDefaults.headerKey]: `${configWithDefaults.headerPrefix} ${token}`,
      };
    }
    return config;
  };

  const requestInterceptor = axiosClient.interceptors.request.use((config) => {
    const configWithPotentialFreshToken = config as ConfigWithTokenRetry;

    if (!configWithPotentialFreshToken.withFreshToken) {
      const currentToken = authConfig.getCurrentToken();
      if (currentToken) {
        return appendTokenToConfig(config, currentToken) as InternalAxiosRequestConfig;
      }
    }

    return config;
  });

  let refreshTokenPromise: Promise<string | undefined> | undefined;

  const responseInterceptor = axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (axios.isAxiosError(error)) {
        if (configWithDefaults.isUnauthenticatedResponse(error)) {
          const errorConfigWithRetry = error.config as ConfigWithTokenRetry;

          if (!errorConfigWithRetry.withFreshToken) {
            errorConfigWithRetry.withFreshToken = true;
            try {
              if (!refreshTokenPromise) {
                refreshTokenPromise = configWithDefaults.refreshToken();

                refreshTokenPromise
                  .then((newToken) => {
                    if (!newToken) {
                      configWithDefaults.onUnauthorizedError();
                    }
                  })
                  .catch(() => {
                    configWithDefaults.onUnauthorizedError();
                  })
                  .finally(() => {
                    refreshTokenPromise = undefined;
                  });
              }

              const newToken = await refreshTokenPromise;

              if (newToken && error.config) {
                const newConfig = appendTokenToConfig(error.config, newToken);

                return axiosClient.request(newConfig);
              }
            } catch (_e) {
              return configWithDefaults.convertError(error);
            }
          }
        }
      }
      return configWithDefaults.convertError(error);
    }
  );

  return () => {
    axiosClient.interceptors.request.eject(requestInterceptor);
    axiosClient.interceptors.response.eject(responseInterceptor);
  };
};
