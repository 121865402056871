import React from 'react';
import { useTranslation } from 'react-i18next';

export const Rating = ({ rating, amount }: { rating: number; amount?: number }) => {
  const { t } = useTranslation('common');
  const hasPartialStar = rating % 1 > 0;
  return (
    <div className="flex items-center gap-1">
      <span className="sr-only">{t('rating.ratingStatus', { rating })}</span>
      <div className="flex w-[100px] gap-1" aria-hidden="true">
        {[...new Array(Math.floor(rating))].map((_, i) => {
          return <Star key={i} percentage={100} />;
        })}
        {hasPartialStar && <Star percentage={(rating % 1) * 100} />}
        {[...new Array(5 - Math.ceil(rating))].map((_, i) => {
          return <Star key={i} percentage={0} />;
        })}
      </div>
      {amount && <span>{t('rating.ratingAmount', { amount })}</span>}
    </div>
  );
};

const Star = ({ percentage }: { percentage: number }) => {
  const percentageFilled = percentage === 100 ? percentage : Math.round(Math.min(percentage, 68));
  const tenBasedPercentage = Math.round((percentageFilled / 100) * 10) * 10;

  return (
    <svg height="16px" width="16px" version="2.0">
      <use xlinkHref={`#rating-star-${tenBasedPercentage}`} />
    </svg>
  );
};

export const RatingStars = () => {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{ width: 0, height: 0 }}
    >
      <defs>
        {[0, 10, 20, 30, 40, 50, 60, 68, 70, 80, 90, 100].map((value) => (
          <symbol id={`rating-star-${value}`} viewBox="0 0 24 23" key={value}>
            <path
              d="M12.8773 1.54544L15.7498 7.35073L22.1587 8.28212C22.963 8.39695 23.2822 9.37939 22.7077 9.95354L18.0734 14.4702L19.1713 20.8496C19.3117 21.6534 18.4691 22.2531 17.7542 21.8831L12.022 18.872L6.28975 21.8831C5.57482 22.2658 4.73222 21.6534 4.87265 20.8496L5.97058 14.4702L1.29798 9.94078C0.710717 9.37939 1.04265 8.38419 1.84695 8.26936L8.25582 7.33797L11.1155 1.54544C11.473 0.818186 12.5071 0.818186 12.8773 1.54544Z"
              fill={`url(#${`fill-${value}`})`}
              stroke="#354B43"
              strokeWidth={1.5}
            />
          </symbol>
        ))}

        {[0, 10, 20, 30, 40, 50, 60, 68, 70, 80, 90, 100].map((value) => (
          <linearGradient id={`fill-${value}`} key={value}>
            <stop offset="0%" stopColor="#354b43" />
            <stop offset={`${value}%`} stopColor="#354b43" />
            <stop offset={`${value}%`} stopColor="white" />
            <stop offset="100%" stopColor="white" />
          </linearGradient>
        ))}
      </defs>
    </svg>
  );
};
